.landingpage {
  margin-top: 0px;
  white-space: pre-line;

  h1 {
    font-weight: 600;
    font-size: 1.875rem;
    letter-spacing: 0.173rem;

    @media (min-width: $breakPointMedium) {
      font-size: 2.813rem;
    }

    @media (min-width: $breakPointExtraLarge) {
      font-size: 3.3rem;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 2rem;
    letter-spacing: 0.073rem;

    @media (min-width: $breakPointMedium) {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 2.8rem;
      line-height: 3rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1px;

    @media (min-width: $breakPointLarge) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5rem;

    @media (min-width: $breakPointLarge) {
      font-size: 1.313rem;
      line-height: 1.7rem;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: 0.013rem;

    @media (min-width: $breakPointMedium) {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }

  ///HEADER SECTION////////////////////////////////////////////////////////////////////////////

  .header-section {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
    background-color: $blue-c500;
    background-image: url('../../../images/landingpages/health_insurance/hintergrund-illu-skyline.jpg');
    background-position: bottom 25px left;
    background-size: 120%;
    background-repeat: no-repeat;
    min-height: 650px;

    @media (min-width: 425px) {
      min-height: 570px;
    }

    @media (min-width: $breakPointMedium) {
      background-position: bottom -10px left;
      background-size: 100% 100%;
      justify-content: center;
    }

    @media (min-width: $breakPointLarge) {
      min-height: 600px;
    }

    @media (min-width: $breakPointExtraLarge) {
      background-size: 70%;
    }

    &__content {
      color: $white-c500;
      z-index: 1;

      h1 {
        margin: 6rem 0 0.8rem;
        padding: 0px 20px;
        z-index: 1;

        @media (min-width: $breakPointMedium) {
          margin: -1rem 0 1rem;
        }

        @media (min-width: $breakPointLarge) {
          margin: 0 0 1.3rem;
        }
      }

      h4 {
        font-weight: 300;
        padding: 0 2.3rem;
      }

      .partner-logo-bubble {
        position: absolute;
        right: 0;
        bottom: 0;

        a {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }

        &__image {
          width: 70%;

          @media (min-width: $breakPointMedium) {
            width: 100%;
          }
        }
      }

      .add-on-text {
        font-weight: 600;
        position: absolute;
        left: 1.25rem;
        bottom: -0.6rem;
        font-size: 0.8rem;
        width: 41%;
        text-align: left;
        line-height: 0.9rem;

        @media (min-width: 555px) {
          width: none;
          bottom: 0.2rem;
        }

        @media (min-width: $breakPointMedium) {
          width: none;
          left: 1.875rem;
          font-size: 1rem;
          bottom: -0.3rem;
        }

        @media (min-width: $breakPointLarge) {
          left: 10rem;
        }

        @media (min-width: $breakPointExtraLarge) {
          left: 24.75rem;
          bottom: -0.2rem;
        }
      }
    }

    &__cloud-img {
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      aspect-ratio: 1/0.4;
    }

    .left {
      background-image: url('../../../images/landingpages/health_insurance/hintergrund-illu-wolke-links.png');
      top: 27.5em;
      left: 0;
      width: 130px;

      @media (min-width: $breakPointMedium) {
        top: 17rem;
        width: 200px;
        left: -3rem;
      }

      @media (min-width: $breakPointLarge) {
        top: 10.5rem;
        left: -5rem;
        width: 300px;
      }

      @media (min-width: $breakPointExtraLarge) {
        left: 0;
      }
    }

    .right {
      background-image: url('../../../images/landingpages/health_insurance/hintergrund-illu-wolke-rechts.png');
      display: none;

      @media (min-width: $breakPointMedium) {
        display: block;
        right: -2rem;
        top: 17rem;
        width: 170px;
      }

      @media (min-width: $breakPointLarge) {
        top: 12rem;
        right: -1rem;
        width: 200px;
      }

      @media (min-width: $breakPointExtraLarge) {
        top: 11rem;
        right: 1rem;
        width: 230px;
      }
    }

    .blue-bottom-line {
      width: 170%;
      position: absolute;
      bottom: -2px;

      @media (min-width: $breakPointMedium) {
        bottom: -3px;
        width: 100%;
      }

      @media (min-width: $breakPointLarge) {
        bottom: -4px;
      }
    }
  }

  ///CHECKMARK SECTION//////////////////////////////////////////////////////////////////////

  .checkmark-section {
    display: flex;
    flex-direction: column;
    padding: 0 1.25rem 0;
    overflow: hidden;

    @media (min-width: $breakPointMedium) {
      flex-direction: row;
      padding: 0 0 0 1.875rem;
    }

    @media (min-width: $breakPointLarge) {
      flex-direction: row;
      padding: 0 0 0 10rem;
    }

    @media (min-width: $breakPointExtraLarge) {
      flex-direction: row;
      padding: 0 0 0 24.75rem;
    }

    h2 {
      margin-top: 2.063rem;
      margin-bottom: 1rem;
      color: $blue-c500;

      @media (min-width: $breakPointMedium) {
        margin-top: 3rem;
        margin-bottom: 27px;
        align-self: flex-start;
      }

      @media (min-width: $breakPointLarge) {
        margin-top: 79px;
        margin-bottom: 27px;
        align-self: flex-start;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;

      @media (min-width: $breakPointMedium) {
        width: 60%;
      }

      @media (min-width: $breakPointLarge) {
        width: 720px;
        padding-right: 5rem;
      }

      @media (min-width: $breakPointExtraLarge) {
        width: 40%;
        padding-right: 5rem;
      }

      &-list {
        list-style: none;
        padding-left: 0;
        width: 100%;
        margin: 0.5rem 0 -0.5rem;

        @media (min-width: $breakPointMedium) {
          margin: 1rem 0 2rem -0.5rem;
        }

        &-element {
          display: flex;
          width: 100%;

          &::before {
            content: '';
            background-image: url('../../../images/landingpages/b2b_clients/checkmark.svg');
            background-size: contain;
            display: block;
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            position: relative;
            top: -0.2rem;
            margin-right: 0.6rem;
          }
        }
      }
    }

    &__image {
      align-self: center;
      margin-bottom: -200px;

      @media (min-width: 375px) {
        margin-bottom: -240px;
      }

      @media (min-width: $breakPointMedium) {
        margin-bottom: -70px;
      }

      @media (min-width: 1024px) {
        margin-bottom: -260px;
      }

      @media (min-width: $breakPointLarge) {
        margin-bottom: -365px;
      }

      @media (min-width: $breakPointExtraLarge) {
        margin-bottom: -400px;
      }

      &-course-phone {
        width: 100%;

        @media (min-width: $breakPointMedium) {
          min-width: 100px;
          max-width: 400px;
          margin: 0 auto;
        }

        @media (min-width: $breakPointLarge) {
          max-width: 480px;
        }
      }
    }
  }

  ///BALLOONI SECTION/////////////////////////////////////////////////////////////////////

  .ballooni-section-b2b {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: $blue-c500;

    h2 {
      color: $white-c500;
      text-align: center;
      margin: 2rem 1.25rem -4.5rem;

      @media (min-width: $breakPointMedium) {
        margin: 1.5rem 10rem 0rem;
      }

      @media (min-width: $breakPointLarge) {
        margin: 0;
        padding: 0 17rem;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin-top: 4rem;
      width: 100%;
      justify-content: center;
      padding: 0 1rem;

      @media (min-width: $breakPointMedium) {
        margin-top: 0.5rem;
      }

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
        margin-top: 25px;
        gap: 3rem;
      }

      .ballooni-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 23px;
        padding: 0 0;
        text-align: center;

        @media (min-width: $breakPointMedium) {
          padding: 0 6rem;
        }

        @media (min-width: $breakPointLarge) {
          width: 350px;
          margin-top: 0;
          padding: 0 0.3rem;
        }

        @media (min-width: $breakPointExtraLarge) {
          width: 420px;
          padding: 0 2rem;
        }

        p {
          color: $white-c500;
          margin-top: 15px;
        }

        a {
          color: $white-c500;
          text-decoration: underline;
        }
      }

      .ballooni1-image,
      .ballooni2-image,
      .ballooni3-image {
        width: 180px;
        height: 180px;

        @media (min-width: $breakPointMedium) {
          width: 200px;
          height: 200px;
        }
      }

      .ballooni1-image {
        background: url('../../../images/landingpages/b2b_clients//illu-zugangs-code.jpg') no-repeat;
        background-size: cover;
      }

      .ballooni2-image {
        background: url('../../../images/landingpages/b2b_clients//illu-registrierung.jpg') no-repeat;
        background-size: cover;
      }

      .ballooni3-image {
        background: url('../../../images/landingpages/b2b_clients//illu-kurs.jpg') no-repeat;
        background-size: cover;
      }
    }

    .blue-line-container {
      width: 102%;
      position: relative;
      top: -1px;
      left: -2px;
      overflow: hidden;

      @media (max-width: 425px) {
        top: -3px;
      }

      @media (max-width: 325px) {
        top: -5px;
      }

      &__ballooni-bottom {
        top: 2px;
      }
    }

    .blue-line {
      width: 100%;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .success-content img {
    @media (min-width: $breakPointLarge) {
      width: 115%;
      margin: 0;
    }
  }
}
/////////////////////////////////////////////////////////

.landingpage-register-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4rem;
  padding: 0 10px;

  p {
    margin: 0.5rem 0;
    color: $black-c500;
    margin: -0.5rem 0 1rem;
    color: $grey-c600;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  a {
    font-weight: 400;
    color: $green-c500;
    text-decoration: underline;
  }

  &__additional-text {
    text-align: center;
    color: $black-c500 !important;
    margin: 1.5rem 0 1.5rem !important;
  }

  .existing-insurance-number {
    margin-top: 32px;

    p {
      margin-bottom: 24px;
    }

    @media (min-width: $breakPointMedium) {
      width: 60%;
    }

    button {
      width: 100%;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: center;

    @media (min-width: $breakPointMedium) {
      width: 60%;
      padding: 1.3rem 0 0;
    }

    select.form-select {
      padding-left: 0.8rem;
      color: $gray-900;

      &.showOptionAsPlaceholder {
        color: $grey-c600;
      }
    }

    .invalid-feedback {
      font-size: 0.875em;
      font-weight: 400;
      margin-left: 2px;
    }

    .fb-error {
      text-align: left;
      font-size: 0.875em;
      margin: -14px 0 15px 2px;
    }

    .form-check-inline {
      margin: 0 0 2rem;
      padding: 0;
    }

    .pad {
      padding: 0 1rem;
    }
  }

  .form-width {
    @media (min-width: $breakPointMedium) {
      width: 50%;
    }
  }
}
