.trust-band {
  $desktop-breakpoint: 1024px;

  &__heading {
    margin-bottom: 1em;
    color: $grey-c800;
    font-size: $font-size-base;
    text-align: center;

    @media (min-width: $desktop-breakpoint) {
      font-size: 1.5625rem;
    }
  }

  .trust-row {
    display: none;
  }

  @media (min-width: $desktop-breakpoint) {
    .trust-row {
      display: block;
    }

    .trust-carousel {
      display: none;
    }
  }
}
