.voucher-manual {
  padding-top: 33px;
  @include media-breakpoint-down(md) {
    padding-top: 70px;
  }

  .balloony-img {
    padding-left: 0;
  }

  .balloony-gift {
    max-width: 100%;
  }

  .headcontent {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .plans__subheading {
      text-transform: uppercase;
      font-size: 24px;
    }

    @include media-breakpoint-down(lg) {
      align-items: center;
    }

    @include media-breakpoint-down(md) {
      padding-top: 20px;
    }
  }

  .steps-content {
    background-color: $grey-c100;
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    min-height: 615px;
    @include media-breakpoint-down(md) {
      min-height: 715px;
    }
    .row {
      justify-content: center;
      align-items: center;
      height: 100%;
      padding-top: 40px;
      @include media-breakpoint-up(md) {
        align-items: stretch;
      }
    }
    .step1,
    .step2,
    .step3,
    .step4 {
      text-align: center;
      margin-right: 36px;
      position: relative;
      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
      &:before {
        display: block;
        position: absolute;
        left: 38%;
        bottom: 92%;
        content: '';
        height: 62.6px;
        width: 64px;
        background-size: cover;
        @include media-breakpoint-between(md, lg) {
          left: 45%;
        }
        @include media-breakpoint-down(md) {
          left: 45%;
        }
      }
    }
    .step1 {
      &:before {
        background-image: url('../../images/voucher_manual/1@2x.png');
      }
    }
    .step2 {
      &:before {
        background-image: url('../../images/voucher_manual/2@2x.png');
      }
    }
    .step3 {
      &:before {
        background-image: url('../../images/voucher_manual/3@2x.png');
      }
    }
    .step4 {
      margin-right: 0;
      &:before {
        background-image: url('../../images/voucher_manual/4@2x.png');
      }
      &:after {
        display: block;
        position: absolute;
        left: 70%;
        content: '';
        height: 187px;
        width: 160px;
        background-size: cover;
        background-image: url('../../images/voucher_manual/happy@2x.png');
        @include media-breakpoint-down(sm) {
          left: 30%;
        }
      }
      .notice {
        color: $green-c500;
        font-weight: $font-weight-semibold;
      }
    }
    .step-box {
      border-radius: 10px;
      box-shadow: 0 0 60px 0 $black-o30;
      background-color: $grey-c100;
      margin-top: 40px;
      padding-top: 3rem;
      padding-bottom: 3rem;
      p {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      @include media-breakpoint-down(lg) {
        margin-bottom: 40px;
      }
    }
  }
  .btn-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
    font-size: 24px;
    .btn-pink-c500 {
      margin-top: 20px;
      color: $grey-c100;
    }

    @include media-breakpoint-down(lg) {
      margin-bottom: 100px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 135px;
    }
  }
  .mac-content {
    margin-top: -85px;
    margin-bottom: 80px;
    img {
      height: auto;
      width: 100%;
    }
  }
}
