.drop-down-type-ahead {
  width: 100%;
  height: 100%;
  border: 1px solid $grey-c500;
  font-size: 0.813rem;
  font-family: $poppins;
  font-weight: 400;
  letter-spacing: 0.125rem;
  color: $black-o50;
  border: none;
  border-bottom: 0.5px solid $white-c500;

  .invalid-feedback {
    font-size: 0.84rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.013rem;
  }

  .form-control:focus {
    border: 2px solid $white-c500;
    box-shadow: none;

    &.is-invalid {
      border: 2px solid $error-c500;
    }
  }

  ul::before {
    content: '';
  }

  ul {
    border-top: 1px solid $white-c500;
    list-style-type: none;
    text-align: left;
    margin: -1.2rem 0 0;
    padding: 0;
    z-index: 1;
  }

  li {
    padding: 8px 15px;
    cursor: pointer;
    border-top: 0.5px solid $white-c500;
    border-left: 2px solid $white-c500;
    border-right: 2px solid $white-c500;
    background-color: $grey-c100;
    margin-bottom: 1px;
  }

  li:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  li:hover {
    background-color: $green-c500;
    color: $white-c500;
  }

  input::placeholder {
    color: $black-o50;
  }
}
