.email-confirmation {
  &__card {
    padding: 3rem 2rem;
    position: relative;

    .mail-img {
      width: 13rem;

      @include media-breakpoint-down(sm) {
        width: 9rem;
      }
    }

    .vogel-img {
      width: 6.5rem;
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;

      @include media-breakpoint-down(md) {
        width: 5rem;
      }

      @media (max-width: 575.98px) {
        width: 4rem;
      }
    }
  }
}
