.card-offer {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(xs) {
    &::before,
    &::after {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    &::before,
    &::after {
      display: block;
      content: '';
      background-image: url('../../../images/landingpages/background_balloon.png');
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      z-index: 0;
    }

    &::before {
      top: -20px;
      right: -16px;
      background-size: 130px;
      width: 130px;
      height: 137px;
    }

    &::after {
      bottom: -40px;
      left: -16px;
      background-size: 160px;
      width: 160px;
      height: 170px;
    }
  }

  .card-header {
    @include media-breakpoint-up(xs) {
      padding-top: 3rem;
    }

    small {
      color: $green-c500;
      text-transform: uppercase;
    }

    h2 {
      font-weight: 100;
      font-size: 2.2rem;
    }
  }

  .card-body {
    @include media-breakpoint-up(xs) {
      padding-bottom: 3rem;
    }
    @include media-breakpoint-up(md) {
      padding: 0rem 10rem 3rem 10rem;
    }

    font-size: 19px;
  }
}

.steps-howto {
  text-align: center;
  padding: 3rem 0;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }

  ul li {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    align-items: center;
    justify-content: center;
    max-width: 240px;
    counter-increment: inst;
    list-style-type: none;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  ul li::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: counter(inst);
    height: 100px;
    width: 100px;
    background-image: url('../../../images/landingpages/kaifu/circle.png');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    color: $white-c500;
    margin-bottom: 0.3em;
    font-size: 4em;
    font-family: $font-family-sans-serif;
    font-weight: 600;
  }
}

//*Hero Kaifu*//
.hero-kaifu {
  text-align: center;

  .plans__heading {
    @include media-breakpoint-up(xs) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      font-size: 2.8rem;
    }
  }
}

//*Brandbar*//
.kaifuandballoon {
  max-width: 100%;
  padding: 4rem 0;
}

//*Howto*//
.howto-kaifu {
  background-color: $grey-c100;
}
.small-explainer {
  font-weight: 300;
}

//*Special Buttons for Kaifu*//
.howto-kaifu,
.hero-kaifu {
  .btn-special {
    position: relative;

    @include media-breakpoint-up(xs) {
      &::before,
      &::after {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      &::before {
        display: block;
        content: '';
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        z-index: 0;
        right: -18%;
      }
    }
  }
}

.howto-kaifu {
  .btn-special {
    @include media-breakpoint-up(lg) {
      &::after {
        display: block;
        content: '';
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        z-index: 0;
      }

      &::before {
        background: url('../../../images/landingpages/hearts_before@2x.png');
        background-size: 100%;
        width: 40px;
        height: 40px;
        right: -18%;
        top: 0px;
      }

      &::after {
        background: url('../../../images/landingpages/hearts_after@2x.png');
        background-size: 100%;
        width: 40px;
        height: 67px;
        left: -18%;
        bottom: 0px;
      }
    }
  }
}

.hero-kaifu {
  .btn-special {
    &::before {
      background: url('../../../images/landingpages/kaifu/balloony_heart@2x.png');
      background-size: 100%;
      width: 90px;
      height: 110px;
      left: -18%;
      top: 0px;
    }
  }
}
