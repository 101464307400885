@use 'sass:math';

@mixin font-size($size-value: 16) {
  font-size: math.div($size-value, 16) + rem;
}

@mixin home-copy() {
  font-size: 0.75rem;
  line-height: 1.5;

  @include media-breakpoint-up(sm) {
    font-size: 1.125rem;
  }
}

@mixin secondary {
  color: $grey-c600;
}

@mixin container-padding-vertical() {
  padding: 5rem 0;
}

@mixin home-h2() {
  padding-top: 2em;
  padding-bottom: 1em;
  color: $grey-c800;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.3333;

  @include media-breakpoint-up(sm) {
    font-size: 2.1875rem;
  }
}

@mixin card-shadow {
  -webkit-box-shadow: 0px 0px 60px 0px $black-o20;
  -moz-box-shadow: 0px 0px 60px 0px $black-o20;
  box-shadow: 0px 0px 60px 0px $black-o20;
}

@mixin dark-card-shadow {
  -webkit-box-shadow: 0px 0px 60px 0px $black-o30;
  -moz-box-shadow: 0px 0px 60px 0px $black-o30;
  box-shadow: 0px 0px 60px 0px $black-o30;
}
