.faq-marketing {
  background-color: $grey-c200;
  padding: 3.75rem 0;
  text-align: center;
  color: $blueGrey-c500;

  &__accordion {
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 2rem;
    }
  }

  .accordion-item .accordion-button.collapsed {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    box-shadow: none;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .accordion-button:not(.collapsed) {
    background-color: $white-c500;
    color: $blueGrey-c500;
    box-shadow: none;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    padding-bottom: 0;
  }

  .accordion-button::after {
    align-self: flex-start;
  }

  .accordion-header {
    border-radius: 6px;
    &__heading {
      color: $blueGrey-c500;
      font-weight: 500;
    }
  }

  .accordion-body {
    background-color: $white-c500;
    text-align: left;
    color: $blueGrey-c500;
    font-weight: 300;
    letter-spacing: 0.3rem;
    margin-top: 0;
    padding-top: 0;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;

    &__strong {
      font-weight: 600;
    }
  }

  &__accordion-item {
    border: none;
    background-color: $grey-c200;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
