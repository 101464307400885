//*LOGIN and REGISTER*//

//Login and Register with E-Mail

.card-email {
  @include media-breakpoint-up(sm) {
    padding: 3.5rem 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 3.5rem 0 1rem 0;
  }

  @media (max-width: 575.98px) {
    padding: 1rem 0 0 0;
  }

  .newsletter-disclaimer {
    color: $grey-c600;
    font-size: 0.8rem;
    font-weight: 300;
    text-align: center;
    margin-top: 1rem;
    a {
      color: $blue-c500;
    }
  }

  .card-header,
  .card-body,
  .card-footer {
    @include media-breakpoint-only(xs) {
      padding-left: 0;
      padding-right: 0;
    }

    @include media-breakpoint-only(sm) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    @include media-breakpoint-only(md) {
      padding-left: 1rem;
      padding-right: 0.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  .card-title {
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }

  .card-link {
    color: $green-c500;
    font-size: 0.75rem;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }

    @media (max-width: 575.98px) {
      margin: 0.5rem 0 0 0;
    }

    @include media-breakpoint-up(lg) {
      margin: 0 0 0 0;
    }

    a {
      text-decoration: underline;
      color: $green-c500;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .card-footer {
    display: block;
    margin-top: 2rem;
    text-align: center;
    margin: 0 0.5rem;
    padding-top: 1rem;

    .sign-in-text {
      padding-top: 1rem;
    }
  }
}

.sign-in-user-with-otp {
  @media (min-width: 992px) {
    .card-email .card-header,
    .card-email .card-body,
    .card-email .card-footer {
      padding-left: 14rem;
      padding-right: 14rem;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .card-email .card-header,
    .card-email .card-body,
    .card-email .card-footer {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
  .sign-in-user-with-otp-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:focus {
        outline: $green-c500 auto 1px;
      }
    }

    .btn-secondary {
      width: 250px;
    }
  }
}

//Login and Register with Google
.card-social {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-row-gap: 31px;
  grid-template-rows: 56px 56px 56px 60px;
  background-color: $grey-c100;

  @include media-breakpoint-up(md) {
    border-radius: 0 10px 10px 0;
    padding: 131px 15px 20px;
  }

  @include media-breakpoint-up(lg) {
    padding: 131px 50px 20px;
  }

  @include media-breakpoint-down(sm) {
    background-color: $white-c500;
    padding: 0 3.5rem 4rem;
  }

  @media (max-width: 575.98px) {
    padding: 0 15px 1rem;
  }

  button {
    width: 100%;
    justify-content: center;
  }

  .card-title {
    font-size: 1.4rem;
    font-weight: 500;
    color: $blue-c500;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1.15rem;
    }
  }

  &.card-social-register {
    grid-template-rows: 56px 56px 60px;
    grid-row-gap: 19px;
    @include media-breakpoint-up(md) {
      padding-top: 259px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 276px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 258px;
    }
  }
}

//Google
.cta-google {
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-google {
    height: 100%;
    border-radius: 8px !important;
    border: 1px solid $grey-c800 !important;
    box-shadow: none !important;
    text-transform: unset;
    background-color: $white-c500;
    display: inline-flex;
    align-items: center;
    color: $black-o50;
    box-shadow: $black-o30 0px 2px 2px 0px, $black-o30 0px 0px 1px 0px;
    padding: 0px;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: Roboto, sans-serif;

    span {
      font-family: $font-family-sans-serif;
      color: $grey-c800 !important;
      font-size: 1.313rem;
      font-weight: 500;
      margin-left: 10px;
      padding: 10px 10px 10px 0px;

      @include media-breakpoint-down(lg) {
        font-size: 1.188rem;
        margin-top: 2px;
      }
    }
  }
  .button-div-info {
    display: none;
    color: $error-c500 !important;
    font-size: 0.75rem;
    text-align: center;
  }
  .alternative-login {
    display: none;
    border: 1px solid $blueGrey-c200;
    border-radius: 5px;
    min-height: 85px;
    height: 100%;
  }
}

.fb-error {
  color: $error-c500 !important;
  font-size: 0.75rem;
  text-align: center;

  a {
    text-decoration: underline;
    color: $error-c500 !important;
  }
}

// Apple

.cta-apple-signin {
  height: 56px;
  width: 100%;
  cursor: pointer;
  outline: none;

  @include media-breakpoint-up(lg) {
    border: none;
  }

  > div {
    max-width: unset !important;
  }
}

.error-apple-siginin {
  margin-left: auto;
  margin-right: auto;
}

//*REGISTER*//

.registration {
  .implicit-acceptance {
    @include secondary;
    padding-top: 1rem;

    a {
      @include secondary;
      text-decoration: underline;
    }
  }
}
