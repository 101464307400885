.library-coupon-ad-tile {
  white-space: pre-line;
  text-align: center;
  background: $blue-c500;
  color: $white-c500;
  border-radius: 0.5rem;
  height: 16rem;

  @include media-breakpoint-up(md) {
    height: 11rem;
  }

  @include media-breakpoint-up(xl) {
    height: 6rem;
  }

  &__headline {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
      line-height: 2.275rem;
    }
  }

  &__button {
    width: 100%;
    font-size: 0.9rem;
    color: $blue-c500;

    @include media-breakpoint-only(xl) {
      white-space: pre-line !important;
    }

    @include media-breakpoint-up(md) {
      font-size: 0.8rem;
    }
  }

  &__illustration-wrapper {
    padding-left: 0;
    position: relative;
  }

  &__illustration {
    width: 11rem;

    @include media-breakpoint-up(md) {
      width: 8rem;
    }
    @include media-breakpoint-up(xl) {
      width: 10.5rem;
      z-index: 13;
      position: absolute;
      bottom: -40px;
      display: block;
      left: -32px;
    }
  }
}
