$border-radius: 0.5rem;

.library-list-item {
  display: inline-flex;
  position: relative;
  margin-bottom: 0.5rem;
  width: 100%;
  color: $white-c500;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.93;
  }

  &--noBadge {
    .library-list-item-badge {
      display: none;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    z-index: 1;
    align-items: flex-start;
    width: 100%;
  }

  &__icon,
  &__locked-icon {
    display: flex;
    align-items: center;
    margin-right: 0.8rem;

    &::before {
      display: block;
      justify-content: center;
      align-content: center;
      height: 1.7rem;
      width: 1.7rem;
      margin-top: 4px;
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__headline {
    width: 100%;
    font-size: 1rem;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $white-c500;

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
      line-height: 1.563rem;
    }
  }

  &__sub-headline {
    width: 100%;
    font-size: 0.875rem;
    letter-spacing: 0.01rem;
    color: $white-o80;
    margin-top: 0.15rem;
  }

  &--course {
    height: 16.875rem;
    align-items: flex-start;
    padding: 1.125rem 3rem 1.125rem 1.125rem;
  }

  &--single {
    height: 6rem;
    align-items: center;
    padding: 1.125rem 3rem 1.125rem 1.125rem;

    @include media-breakpoint-up(md) {
      height: 7.938rem;
    }
  }

  &--single,
  &--course {
    .library-list-item__icon {
      &::before {
        background-image: url('../../images/webplayer/icon-play-circle.svg');
      }
    }

    .library-list-item__locked-icon {
      &::before {
        background-image: url('../../images/webplayer/icon-lock-circle.svg');
      }
    }
  }

  &__new-badge,
  &__special-badge {
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: $border-radius;
    border-top-right-radius: 0.42em;
    border-bottom-left-radius: $border-radius;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.04rem;
    color: $white-c500;
  }

  &__new-badge {
    width: 3rem;
  }

  &__special-badge {
    width: 4.688rem;
  }

  &__no-badge {
    display: none;
  }

  &__category-icon {
    position: absolute;
    bottom: 1.1rem;
    right: 0;
    width: 5.188rem;
    aspect-ratio: 83/50;
    display: block;

    @include media-breakpoint-up(md) {
      bottom: 1.5rem;
    }
  }
}
