.thank-you {
  color: $grey-c800;
  font-size: 0.9rem;
  letter-spacing: 0.8px;

  .hero {
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-image: url('../../images/thank_you/hero_bench@3x.png');
    background-repeat: no-repeat;

    @include media-breakpoint-up(xs) {
      background-position-x: -17%;
      background-position-y: bottom;
      text-align: center;
      background-size: 80%;
    }

    @include media-breakpoint-up(sm) {
      background-position-x: right;
      background-position-y: 230px;
      text-align: left;
    }

    @include media-breakpoint-up(md) {
      background-position-y: 145px;
    }

    @include media-breakpoint-up(xl) {
      background-position-y: 250px;
      background-size: 50%;
    }

    @include media-breakpoint-up(xl) {
      background-position-y: bottom;
    }
  }

  &__card {
    padding: 3.5rem 4rem 3rem 4rem;
    height: auto;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      padding: 3rem 1rem 2.5rem 1rem;
    }
    @include media-breakpoint-down(sm) {
      padding: 3rem 1rem 2.5rem 1rem;
    }

    @media (max-width: 575.98px) {
      padding: 2rem 0.5rem 1.8rem 0.5rem;
    }

    img {
      width: 30%;
      margin-top: 2rem;
      margin-bottom: 3rem;

      @include media-breakpoint-down(sm) {
        width: 35%;
      }

      @media (max-width: 575.98px) {
        width: 40%;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  .bottom-section {
    background-color: $grey-c100;

    @media (max-width: 575.98px) {
      text-align: center;
    }

    @include media-breakpoint-up(sm) {
      margin-top: -4.5rem;
    }

    .image {
      overflow: hidden;
      margin-top: -6rem;

      @include media-breakpoint-up(xs) {
        margin-right: -4rem;
      }
    }

    .inner {
      position: relative;
      top: 6rem;
    }

    .mt-6 {
      margin-top: 6rem;
    }

    img {
      overflow: hidden;
      width: 19rem;
      margin-top: -6rem;

      @include media-breakpoint-down(lg) {
        width: 18rem;
        margin-top: -4rem;
      }

      @include media-breakpoint-down(md) {
        width: 16rem;
        margin-top: -2rem;
      }

      @include media-breakpoint-down(sm) {
        width: 14rem;
        margin: 0 0 -4rem;
      }
    }

    .description {
      margin-top: -2rem;

      &::before {
        content: '';
        display: block;
        left: calc(50%);
        transform: translateX(-50%);
        position: relative;
        width: 4.4rem;
        height: 4.4rem;
        background-image: url('../../images/thank_you/questionmark.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }

      h3.card-title {
        @extend .card-title;
        font-size: 0.9rem;

        @include media-breakpoint-down(sm) {
          font-size: 1.2rem;
          line-height: 1.4rem;
        }
      }
    }
  }
}

.thank-you-logged-out {
  margin-top: 7.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 4rem;
  }

  .title {
    color: $blueGrey-c500;
    font-family: $font-family-sans-serif;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;

    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }

  .terms {
    color: $blueGrey-c500;
    font-size: 13px;

    a {
      color: $blueGrey-c500;
      font-size: 13px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .associate {
    &::before {
      content: '';
      background-image: url('../../images/thank_you/purchase_association.png');
      position: absolute;
      top: -5rem;
      left: calc(50% - 7.5rem);
      height: 6.07rem;
      width: 15rem;
      background-size: contain;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        left: calc(50% - 6rem);
        height: 4.858rem;
        width: 12rem;
      }
    }
  }

  .fb-error {
    color: $error-c500 !important;
    font-size: 0.8rem;
    text-align: start;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
}
