//Poppins in 300 and 600 from fonts.google.com
//implemented globally in application.html.haml

/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/poppins-v19-latin-200.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/poppins-v19-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/poppins-v19-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/poppins-v19-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/poppins-v19-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/poppins-v19-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/poppins-v19-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/poppins-v19-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v19-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/poppins-v19-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$poppins: 'Poppins', Helvetica, Arial, sans-serif;

body {
  font-family: $font-family-sans-serif; //from bootstrap overrides
  font-weight: 300;
}

h1,
h2 {
  font-family: $font-family-sans-serif;
  font-weight: 500;
}

h2 {
  font-size: 1.5rem;
}

h3,
h4,
h5 {
  font-family: $font-family-sans-serif;
  font-weight: 600;
}

strong,
.font-weight-bold {
  font-family: $font-family-sans-serif;
  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.semiBold {
  font-family: $font-family-sans-serif;
  font-weight: 600;
}

.letter-spacing-02 {
  letter-spacing: 0.2px;
}

.letter-spacing-04 {
  letter-spacing: 0.4px;
}

.letter-spacing-06 {
  letter-spacing: 0.6px;
}

.letter-spacing-08 {
  letter-spacing: 0.8px;
}

.font-weight-400 {
  font-weight: 400;
}
.text-italic {
  font-style: italic;
}
