$amount-font-size-xs: 2.5rem; // not more, so that "159,99 E" fits into one line at 320px width
$amount-font-size-sm: 3.8rem;
$amount-font-size-md: 4rem;

$vertical-overhang: 2vh;
$badge-padding-y: calc(#{$input-btn-padding-y} / 2);
$badge-padding-y-tall: $input-btn-padding-y;
$badge-line-height: 2em;

// overhang + line-height of badge
$compensation-for-badge-and-overhang-base: calc(-#{$vertical-overhang} + #{$badge-line-height});

// add padding of badge
$compensation-for-badge-and-overhang: calc(#{$compensation-for-badge-and-overhang-base} + 2 * #{$badge-padding-y});

// add padding of badge for tall layouts
$compensation-for-badge-and-overhang-tall: calc(#{$compensation-for-badge-and-overhang-base} + 2 * #{$badge-padding-y-tall});
.plans-btn-kk {
  &__title {
    color: $blueGrey-c500;
    font-size: 1.375rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 0.6rem 0.7rem 0 0.7rem;
      margin-bottom: 0;
    }

    @media (max-width: 575.98px) {
      font-size: 0.95rem;
      line-height: 0.9rem;
      padding: 0 1.3rem 1.1rem 1.3rem;
    }
  }
  &__interval {
    margin-bottom: 0.4rem;
    font-size: 1em;
    color: $blue-c600;
    text-align: center;
    display: inline-block;

    @include media-breakpoint-down(lg) {
      font-size: 1rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 0.95rem;
      margin-bottom: 0;
    }

    @media (max-width: 575.98px) {
      margin-top: 0.3rem;
    }
  }
}

.plans-box-kk {
  background-color: $white-c500;
  border-color: $green-c500;
  border-radius: 15px;
  border: 2px solid $green-c500;
  color: $grey-c100;

  font-size: $font-size-base_kk;
  justify-content: space-between;
  white-space: inherit; // allow wrapping (override Bootstrp Button style
  height: 100%;
  width: 100%;

  @include card-shadow;

  @include media-breakpoint-up(md) {
    min-height: 21rem;
  }
  @include media-breakpoint-up(xs) {
    padding: 0;
    margin: 0 5rem;
  }

  &:hover,
  &.active,
  &.hover.active {
    background-color: $white-c500;
    @include dark-card-shadow;

    &__call-to-action {
      background-color: $grey-c100;
      color: $grey-c200;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):active {
    background-color: $white-c500;
    border-color: $green-c500;
  }

  &__info-text {
    color: $grey-c600;
    text-transform: none;
    font-size: $font-size-base_kk;
    letter-spacing: 0;

    .legal-link-box {
      text-decoration: underline;
      text-transform: none;
      font-size: $font-size-base_kk;
      letter-spacing: 0;
    }
  }

  &__legal {
    text-transform: none;
    padding-top: 1rem;
    color: $blueGrey-c500;
    text-align: left;
    padding-left: 15px;
    @include media-breakpoint-only(md) {
      padding-left: 0;
    }

    @include media-breakpoint-down(md) {
      font-size: 0.95rem;
    }
  }

  &__amount {
    margin-bottom: 0;
    font-size: 4rem;
    font-weight: 300;
    line-height: 1em;
    color: $blueGrey-c500;

    @include media-breakpoint-up(md) {
      font-size: $amount-font-size-md;
    }

    @include media-breakpoint-up(xs) {
      font-size: $amount-font-size-sm;
    }
  }
}
.kk_info_box {
  @include media-breakpoint-up(md) {
    min-height: 21rem;
  }
}
.kk-info-content {
  @include media-breakpoint-up(md) {
    min-height: 21rem;
  }

  .wrapper {
    position: relative;

    .dropdown-arrow-klick-area {
      z-index: 10;
      position: absolute;
      right: 0.25rem;
      top: 0.9rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      background: transparent;
    }

    .dropdown-toggle {
      .form-control {
        background-color: $white-c500;
        border-radius: 8px;
        border: solid 0.5px $grey-c600;
        &:focus {
          box-shadow: none;
        }
      }
      &::after {
        height: 100%;
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        right: 1rem;
        top: 1.89rem;
      }

      .dropdown-item {
        color: $grey-c800;
        &:hover,
        &:focus {
          background-color: $green-c500;
        }
        &.headline {
          font-family: poppins-semibold;
          color: $blue-c800;
          pointer-events: none;
          cursor: default;
          background-color: $blue-c200;

          &:last-child {
            margin-top: 1rem;
          }
        }
      }
      .dropdown-menu {
        &.show {
          border: solid 0.5px $grey-c600;
          border-top: 0;
          margin-top: -4px;
        }
      }
    }
  }

  .content_table_kk_check {
    table,
    th,
    td {
      border-collapse: collapse;
    }
    th {
      padding: 0 7px;
    }
    td {
      padding: 5px;
      font-size: 0.8rem;
      color: $grey-c800;

      @include media-breakpoint-up(sm) {
        font-size: 0.9rem;
      }

      @include media-breakpoint-only(md) {
        font-size: 0.7rem;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
    table {
      border-radius: 12px;
      border: solid 2px $green-c500;
      background-color: $white-c500;
      thead {
        font-size: 0.875rem;
        background-color: $green-c500;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $white-c500;
      }
      tfoot {
        border-top: solid 1px $green-c500;
        padding: 0 2px;
      }
      .text-right {
        text-align: right;
      }
    }
  }
  .reset-link {
    text-decoration: underline;
    color: $grey-c800;
    margin-bottom: 1rem;
    cursor: pointer;
  }
}
