.rating-marketing {
  background-color: $white-c500;
  color: $blueGrey-c500;
  padding: 4.063rem 0 3.75rem;

  @include media-breakpoint-up(md) {
    padding: 3.5rem 3rem 6.25rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 5.5rem 2rem 4.25rem;
  }

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.688rem;
  }

  &__img {
    width: 80%;

    @include media-breakpoint-up(md) {
      width: 20rem;
    }

    @include media-breakpoint-up(lg) {
      width: 25rem;
    }

    @include media-breakpoint-up(xl) {
      width: 30rem;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1.5rem;
  }

  &__store-section {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
  }

  &__rating-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
    width: 50%;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }
  }

  &__app-badge-row {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      height: 2rem;
      justify-content: flex-start;
    }
  }

  &__distance-wrapper {
    margin: -1rem 0 1rem;

    @include media-breakpoint-up(md) {
      margin: 1.2rem 0 1rem;
    }
    @include media-breakpoint-up(lg) {
      margin: 1.5rem 0 0.7rem;
    }
  }

  a {
    color: $white-c500;
    text-decoration: none;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  &__font-small {
    font-size: 0.78rem !important;
    display: inline-block;
    white-space: nowrap;

    @media (min-width: 375px) {
      font-size: 1rem !important;
    }

    @include media-breakpoint-up(sm) {
      font-size: 0.7rem !important;
    }

    @include media-breakpoint-up(md) {
      font-size: 1rem !important;
      padding-left: 0.2rem;
    }
  }

  &__number {
    line-height: 0;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      margin: 0.8rem 0 1.8rem;
    }

    @include media-breakpoint-up(lg) {
      margin: 0.1rem 1.3rem 0 0;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 1.1rem 0 0;
    }
  }
}
