.library-app-ad-tile {
  white-space: pre-line;
  text-align: center;
  background: $green-c500;
  color: $white-c500;
  border-radius: 0.5rem;
  min-height: 31.25rem;
  overflow: hidden;
  padding: 3.75rem 1.25rem 0;

  @include media-breakpoint-up(md) {
    padding: 2.5rem 0 0;
    min-height: 15.625rem;
  }

  @include media-breakpoint-up(lg) {
    min-height: 17.5rem;
  }

  &__headline {
    font-size: 1.4rem;
    line-height: 1.8rem;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
      line-height: 2.275rem;
    }
  }

  &__button {
    width: 100%;
    font-size: 1.125rem;
    color: $green-c500;

    @include media-breakpoint-up(md) {
      width: 70%;
      font-size: 1rem;
    }
  }

  &__btn-wrapper {
    margin: 2.188rem 0 0;

    @include media-breakpoint-up(md) {
      margin: 1.5rem 0;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    justify-content: center;
    display: flex;
  }

  &__illustration {
    width: 27rem;
    margin: 1.125rem 0 0;

    @include media-breakpoint-up(md) {
      width: 30rem;
      margin: -4.6rem 0 0 5rem;
    }
  }
}
