.subscription-status-btn-section button,
.sepa button {
  background-color: $grey-c100;
  color: $grey-c800;
  box-shadow: none;
  border: none;
  width: 7rem;
  height: 3.4rem;
  font-family: 'Poppins';
  font-weight: 600;
  position: relative;
  &:hover,
  &:disabled,
  &:focus {
    background-color: $grey-c100;
    border: none;
    box-shadow: 2px 2px 6px $grey-c600;
    color: $grey-c800;
    opacity: 1;
  }
}
.subscription-status-btn-section button:first-child {
  background: $grey-c100 url('../../images/checkout/img-credit-card.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  margin-right: 1.5rem;
}
