.health-insurance-marketing-page {
  white-space: pre-line;
  padding-top: 6rem;
  background-color: $green-c500;

  @include media-breakpoint-up(md) {
    padding-top: 7rem;
  }

  h1 {
    font-weight: 600;
    font-size: 1.7rem;

    @media (min-width: 375px) {
      font-size: 2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.3rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 3.375rem;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.375rem;
      line-height: 2.85rem;
    }
  }

  .poppins-light {
    font-weight: 300;
  }

  h4 {
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1rem;

    @media (min-width: 375px) {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.375rem;
      line-height: 1.7rem;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;
    letter-spacing: 0.013rem;

    @media (min-width: $breakPointMedium) {
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }
}
