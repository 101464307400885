.success-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 130%;
    margin: 2rem 0 0;

    @media (min-width: $breakPointMedium) {
      width: 100%;
      margin: 0;
    }

    @media (min-width: $breakPointLarge) {
      width: 130%;
      margin: 0;
    }
  }

  &__app-badge-wrapper {
    justify-content: space-evenly;
    display: flex;
    gap: 2rem;

    @media (min-width: $breakPointMedium) {
      gap: 4rem;
    }
  }

  a {
    color: $white-c500;
    text-decoration: none;

    @media (min-width: $breakPointMedium) {
      margin: 2.5rem 0;
      width: 45%;
    }
  }

  &__btn {
    width: 100%;
  }

  &__btn-wrapper {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
  }

  ///////Newsletter Dak
  .dak-bgm-newsletter {
    $dak-orange: #ef7d00;
    $dak-orange-darker: darken($dak-orange, 10%);

    background-color: $grey-c100;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 10px 2rem;
    margin-top: 2rem;

    @media (min-width: $breakPointMedium) {
      height: 23.5rem;
      padding: 4rem 10px 2rem;
    }

    .orange {
      color: $dak-orange;
    }

    .gray-blue {
      color: $blueGrey-c500;
    }

    &__dak-logo {
      width: 75px;
      height: 52px;
      margin-bottom: -1rem;

      @media (min-width: $breakPointMedium) {
        margin-bottom: -2rem;
      }
    }
  }
}
