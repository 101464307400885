.upgrade-subscription {
  .plans__subheading {
    margin-top: 0.5rem;
    strong {
      font-weight: 400;
    }
  }
  .form-check-label {
    @media (max-width: 575.98px) {
      font-size: 0.8rem;
    }
  }
  .info,
  .subscription-disclaimer {
    font-size: 0.7rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.62;
    color: $grey-c600;
  }
  .plans-btn__amount {
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      font-weight: unset;
    }
  }
  .amount_content {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;

    .plans-btn {
      &__original-amount {
        @include media-breakpoint-down(sm) {
          padding-top: 0.35rem;
        }

        &::after {
          right: 6rem;
          width: 3rem;
          // the fuck of @include media-breakpoint-down, media-breakpoint-up, media-breakpoint-only and media-breakpoint-between is not working....what ever why back to the old school
          @media (max-width: 1024.98px) {
            right: 4rem;
            width: 3rem;
          }

          @media (min-width: 769px) and (max-width: 991px) {
            right: 7rem;
            width: 3rem;
          }

          @media (max-width: 768.98px) {
            right: 7rem;
            width: 3rem;
          }

          @media (max-width: 425.98px) {
            right: 7.5rem;
            width: 2.5rem;
          }

          @media (max-width: 375.98px) {
            right: 6rem;
            width: 2.5rem;
          }

          @media (max-width: 320.98px) {
            right: 4rem;
          }
        }
      }
    }
  }

  .card {
    @include media-breakpoint-up(xs) {
      background-color: $white-c500;
      border-radius: 10px;
      -webkit-box-shadow: 0px 0px 10px 0px $black-o30;
      -moz-box-shadow: 0px 0px 10px 0px $black-o30;
      box-shadow: 0px 0px 10px 0px $black-o30;
    }

    &-info {
      @media (min-width: 1024px) {
        padding: 3rem 1rem;
      }
    }
    &-body {
      background-color: transparent;
      border: 0;

      @include media-breakpoint-up(xs) {
        padding: 0;
      }
      @include media-breakpoint-down(sm) {
        padding: 3.5rem 1.5rem 2rem 1.5rem;
      }

      .fetching-spinner {
        min-height: 3rem;
      }

      .upgrade-spinner {
        margin: 0 auto;
      }
    }

    &-stoerer {
      @extend .card;

      &::before {
        content: '';
        background-image: url('../../images/upgrade_subscription/upgrade-monat-jahr.svg');
        position: absolute;
        top: -46px;
        left: calc(50% - 7rem);
        height: 8.8rem;
        width: 13rem;
        background-size: contain;
        background-repeat: no-repeat;
        object-fit: contain;

        @include media-breakpoint-down(sm) {
          height: 6rem;
          width: 6rem;
        }

        @media (max-width: 575.98px) {
          height: 6.4rem;
          width: 100%;
          left: -0.8rem;
          top: -49px;
          background-position-x: center;
        }
      }
    }

    .card-important-hint {
      font-weight: 600;
      color: $green-c500;
      text-align: center;
      padding: 0;
      font-size: 1.8rem;
      letter-spacing: 0.9px;
    }

    .card-info-text {
      font-weight: 300;
      text-align: center;
      padding: 0 0 3rem 0;

      @include media-breakpoint-down(lg) {
        padding: 0 0 1.5rem 0;
      }

      @include media-breakpoint-down(md) {
        padding: 0 1.5rem 0.5rem 1.5rem;
      }
      @include media-breakpoint-down(sm) {
        padding: 0 2rem 1.5rem 2rem;
      }

      @media (max-width: 575.98px) {
        padding: 0 0 0.5rem 0;
      }
    }

    .card-header,
    .card-footer {
      background-color: transparent;
      border: 0;

      @include media-breakpoint-up(xs) {
        padding: 0;
      }

      @include media-breakpoint-up(sm) {
        padding: 1rem 2.5rem;
      }
    }

    .card-title {
      color: $green-c500;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 500;
    }

    .card-footer {
      color: $grey-c800;
      font-size: 10px;
      @include media-breakpoint-up(sm) {
        padding: 2.5rem 0rem 0rem 0rem;
      }

      @include media-breakpoint-up(xs) {
        margin-top: 0rem;
        padding: 0;
      }

      @include media-breakpoint-up(md) {
        padding: 1rem 0.5rem;
      }
    }
  }

  .card-decorated {
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;
    }

    &_top {
      &::before {
        content: '';
        @extend .balloon-gray-bg-top;
        top: -34px;
        right: -41px;
        width: 130px;
        height: 130px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &_bottom {
      &::after {
        content: '';
        @extend .balloon-gray-bg-bottom;
        bottom: -51px;
        left: -53px;
        width: 150px;
        height: 150px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
  .upgrade-subscription-user-unkown {
    .card {
      &-stoerer {
        &::before {
          content: '';
          background-image: url('../../images/upgrade_subscription/user-unbekannt.svg');
          position: absolute;
          top: -46px;
          left: calc(50% - 7rem);
          height: 8.8rem;
          width: 13rem;
          background-size: contain;
          background-repeat: no-repeat;
          object-fit: contain;

          @include media-breakpoint-down(sm) {
            height: 6rem;
            width: 6rem;
          }

          @media (max-width: 575.98px) {
            height: 6.4rem;
            width: 100%;
            left: -0.8rem;
            top: -49px;
            background-position-x: center;
          }
        }
      }
    }
    .plans-btn__title {
      font-size: 32px;
      color: $pink-c500;
      margin-bottom: 1rem;
      @media (max-width: 575.98px) {
        line-height: 1.9rem;
        padding: 0;
      }
    }
  }
  .upgrade-subscription-wrong-abo {
    .card {
      &-stoerer {
        &::before {
          content: '';
          background-image: url('../../images/upgrade_subscription/upgrade-monat-jahr-deaktiviert.svg');
          position: absolute;
          top: -46px;
          left: calc(50% - 7rem);
          height: 8.8rem;
          width: 13rem;
          background-size: contain;
          background-repeat: no-repeat;
          object-fit: contain;

          @include media-breakpoint-down(sm) {
            height: 6rem;
            width: 6rem;
          }

          @media (max-width: 575.98px) {
            height: 6.4rem;
            width: 100%;
            left: -0.8rem;
            top: -49px;
            background-position-x: center;
          }
        }
      }
      &-body {
        .plans-btn__title {
          font-size: 32px;
          color: $pink-c500;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
          @media (max-width: 575.98px) {
            line-height: 1.9rem;
            padding: 0;
          }
        }
      }
    }
  }
}
