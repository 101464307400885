.subscription-teaser {
  background-color: $grey-c100;
  color: $blueGrey-c500;

  .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bench-image {
    width: 60%;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  .content {
    padding: 1rem 0 1rem;

    @include media-breakpoint-up(md) {
      padding: 2.8rem 1rem 2.8rem 0;
    }
    @include media-breakpoint-up(lg) {
      @include container-padding-vertical();
    }
  }

  .button-section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;

    @include media-breakpoint-up(md) {
      align-items: flex-start;
      text-align: left;
    }
  }
}
