.panel {
  // the value 74/1440 originates from the design, where a 74px horizontal
  // padding is employed on a 1440px viewport
  // The top-/bottom padding should *look* equal. The bottom padding is higher
  // to compensate for an optical illusion (at the top there's just a heading on
  // white, whereas at the bottom there's a full-colored button)
  padding: 2em calc(74vw / 1440 * 100) 3em;
  background-color: $white-c500;

  .heading {
    margin-bottom: 1.5em;
    text-align: center;
  }
  .subscription-disclaimer {
    padding-top: 2vh;
    color: $blueGrey-c500;
  }

  &__checkout-hint {
    // Smaller padding. In particular, on iPhone 5 in landscape, this results
    // in still showing the top half of the "Login" heading above the fold.
    padding-bottom: 2em;
    background-color: transparent;
    text-align: center;

    @media (max-width: 575.98px) {
      text-align: center;
    }

    img {
      // Mobile-first, don't show image. Info text and image together push
      // the actual Login panel below the fold (e.g. on iPhone 5), so users
      // would be confused as to how to proceed.
      display: none;
      width: 100%;

      @media (min-height: 667px) {
        display: block;
      }
    }
  }
}

.plans {
  margin-bottom: 3rem;

  &__heading {
    text-align: center;
    font-size: 3.375rem;
    font-weight: 300;
    margin-bottom: 1.75rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.7rem;
      font-weight: 600;
    }
  }

  &__subheading {
    text-align: center;
    font-weight: $font-weight-light;
    font-size: 16px;
    margin-bottom: 2rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.8rem;
    }

    @media (max-width: 575.98px) {
      line-height: 1.3rem;
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
    }
  }

  .subscription-disclaimer {
    padding-top: 2vh;
    color: $blueGrey-c500;
    font-weight: 400;
    font-size: 0.75rem;
  }
}

$badge-line-height: 2em;
$text-style: 0.75em;
$text-color: $blue-c600;

@mixin textStyles {
  white-space: pre-line;
  font-size: $text-style;
  color: $text-color;
}

@mixin responsiveMaxWidth {
  @media (max-width: 575.98px) {
    line-height: 1.2rem;
  }
}

.subscriptions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 10px 0;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 $black-o30;
    flex-grow: 1;
    position: relative;

    &:hover,
    &.active,
    &.hover.active {
      border: 1px solid $green-c500;
      background-color: $white-c500;
      box-shadow: 0px 0px 25px 5px $black-o50;

      &.border-pink-c500 {
        border-color: $pink-c500;
      }
    }
  }

  .card-header {
    color: $white-c500;
    display: flex;
    font-weight: $font-weight-bold;
    justify-content: center;
    letter-spacing: 0.08rem;
    line-height: $badge-line-height;
    height: 2.5rem;
    position: absolute;
    width: calc(100% + 2px);
    border-radius: 10px 10px 0 0;
    left: -1px;
    justify-items: center;
    align-items: center;
    text-transform: uppercase;
  }
  .card-header-spacer {
    height: 2.5rem;
  }

  .card-decoration-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 4.5rem;
    height: 4.5rem;
  }

  .card-price {
    margin: 0 0 0.5rem;
    font-size: 1.5em;
    font-weight: $font-weight-bold;
    color: $blue-c600;
  }

  .card-price-reduced {
    color: $green-c600;
  }

  .card-crossed-out-price {
    color: $blue-c600;
    text-decoration: line-through;
    font-size: 1.125em;
    font-weight: $font-weight-bold;
  }

  .card-interval {
    @include textStyles;
  }

  .card-call-to-action-btn {
    width: 100%;
    max-width: 16rem;
    padding: 0.75rem 1rem;
    border-width: 1px;
    @include textStyles;
    @include responsiveMaxWidth;
  }

  .card-plan-title {
    text-align: center;
    margin: 0 0 0.5rem;
    font-size: 1.5em;
    font-weight: $font-weight-bold;
    color: $blue-c600;

    @include media-breakpoint-only(md) {
      font-size: 1.25em;
    }
  }

  .card-caption {
    margin-top: 1rem;
    @include textStyles;
  }

  .card-insurance-image {
    width: 100%;
    padding: 0 1rem;
    margin-bottom: 3rem;
  }

  .card-discount-image {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}
