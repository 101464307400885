.terms-modal {

  .modal-content {
    // In Bootstrap, the modal border radius can only be
    // disabled by setting the global setting "enabled-rounded" to false,
    // but that would also remove the border radii on buttons (etc.)
    // Hence the specific setting here.
    border-radius: 0;
    overflow: hidden;
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 75vh;
    min-height: 75vh;
    -webkit-overflow-scrolling: touch;
    display: inline-block;

    h1, h2, h3, h4 {
      margin: 0 0 8px;
      font-weight: 600;
      line-height: 1.75;
      font-family: poppins-semibold;

    }

    h1 {
      font-size: 1.25rem;
    }

    h2 {
      font-size: 1rem;
    }

    h3, h4 {
      font-size: 0.75rem;
      text-align: left;
    }

    .mime-elements-paragraph, p, strong, ul {
      font-size: 12px;
    }

    ul {
      margin: 12px 0;
      padding: 0;

      li {
        margin-left: 24px;
        margin-bottom: 0;
      }
    }

  }

  .hidden-on-webapp {
    display: none;
  }

  .btn-primary {
    color: $white-c500;
    background-color: $blue-c500;
    border-color: $blue-c500;
  }
}
