.app-ribbon {
  display: flex;
  position: relative;
  margin-bottom: 2.5em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;

  @media (max-width: 575.98px) {
    .btn {
      min-width: 230px;
    }
  }

  @include media-breakpoint-up(md) {
    height: 26em;
    margin-bottom: 0;
    background: $grey-c100;
  }

  &__bg-image {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline;
    }
    &::after {
      content: '';
      position: absolute;
      width: 38em;
      height: 38em;
      top: 1em;

      background-image: url('../../images/webplayer/slopePhone.png');
      background-repeat: no-repeat;
      background-size: contain;

      @include media-breakpoint-up(md) {
        transform: rotate(0deg);
        left: -8.8em;
      }

      @include media-breakpoint-up(lg) {
        transform: rotate(30deg);
        left: -8em;
      }
    }
  }
}
