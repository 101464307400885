.refund-marketing {
  background-color: $green-c500;
  color: $white-c500;
  padding: 3.75rem 0 0;

  @include media-breakpoint-up(lg) {
    padding: 7.5rem 0 5rem;
  }

  @include media-breakpoint-up(lg) {
    min-height: 30rem;
    background-repeat: no-repeat;
    background-position: right 70%;
    background-size: 30rem;
    background-image: url('../../../../images/landingpages/health_insurance_marketing/refund_balloonie_desktop.png');
  }

  @include media-breakpoint-up(xxl) {
    background-size: 38rem;
  }

  &__headline {
    margin: 0 0 1.25rem;

    @include media-breakpoint-up(md) {
      margin: 0 0 1.875rem;
    }
  }

  &__btn-container {
    margin: 1.25rem 0 0;
    z-index: 1;

    @include media-breakpoint-up(md) {
      margin: 1.875rem 0 0;
    }
  }

  &__img-container {
    background-image: url('../../../../images/landingpages/health_insurance_marketing/refund_balloonie_mobile.png');
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    height: 12rem;

    @include media-breakpoint-up(md) {
      height: 24rem;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__list {
    list-style: none;
    padding-left: 0;
    width: 100%;
    margin: 0.5rem 0 0.8rem;

    @include media-breakpoint-up(md) {
      margin: 1.8rem 0 2rem -0.5rem;
    }

    &-element {
      display: inline-flex;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      padding-bottom: 0;

      &::before {
        content: '';
        background-image: url('../../../../images/landingpages/checkmark_green.svg');
        background-size: 25px;
        display: block;
        background-repeat: no-repeat;
        min-width: 25px !important;
        height: 25px;
        position: relative;
        margin-top: 0.2rem;
        margin-right: 0.6rem;
      }
    }
  }
}
