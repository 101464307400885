.trust-carousel {
  .carousel-inner {
    padding-bottom: 3rem; // ensure space for indicators
  }

  .carousel-indicators button {
    width: 1em;
    height: 1em;
    margin: 0 0.25em;
    border-radius: 1em;
    background-color: $grey-c600;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .carousel-indicators .active {
    background-color: $grey-c800;
  }
}
