main.buy-gift {
  margin-top: 7rem;
}

.buy-gift {
  color: $grey-c800;
  font-size: 0.9rem;
  letter-spacing: 0.8px;

  &__heading {
    text-align: center;
    font-size: 64px;
    font-weight: 300;
    margin-bottom: 0;

    @include media-breakpoint-down(lg) {
      font-size: 64px;
      font-weight: 300;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 0.6rem;
    }

    @media (max-width: 575.98px) {
      margin-bottom: 0.5rem;
    }
  }

  &__subheading {
    text-align: center;
    font-weight: $font-weight-light;
    font-size: 16px;
    margin-bottom: 2rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.8rem;
    }

    @media (max-width: 575.98px) {
      line-height: 1.3rem;
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
    }
  }
  &__spinner {
    height: 6rem;
  }
  &__wrapper-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
    }
    & > div:nth-child(2) {
      margin: 0 1.5rem;
    }
  }

  &__wrapper-works {
    background-color: $grey-c100;
    height: 100%;
    z-index: 500;
    padding-bottom: 4rem;

    &-heading {
      font-family: Poppins;
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.3;
      text-align: center;
      color: $blueGrey-c500;
      margin-top: 3rem;
    }
    &-img {
      max-width: 364px;
      width: 100%;
    }
    &-bubble {
      display: flex;
      justify-content: center;
    }
    &-bubble-img {
      max-width: 80px;
      width: 100%;
    }
    &-bubble-headline {
      font-family: Poppins;
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: 0.4px;
      text-align: center;
      color: $blueGrey-c500;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }
    &-bubble-text {
      font-family: Poppins;
      font-size: 1rem;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.3px;
      text-align: center;
      color: $blueGrey-c500;
    }
  }
}
