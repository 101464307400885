.instruction-card {
  font-family: $font-family-sans-serif;

  &-header {
    display: flex;
    flex-direction: row;
  }

  &-count {
    width: 3.625rem;
    height: 3.5rem;
    object-fit: contain;
    background: url('../../../../images/landingpages/health_insurance/count.svg') no-repeat;

    span {
      color: $white-c500;
      font-size: 2.0625rem;
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 0.3rem;

      @include media-breakpoint-down(md) {
        width: 3.8rem;
      }
    }
  }

  &-headline {
    padding-top: 0.1rem;
    font-weight: $headings-font-weight;
    line-height: 1.5;

    span {
      display: block;
      font-weight: $font-weight-bold;
      font-size: 1.375rem;
      line-height: 1.43rem;
    }
  }

  &-body {
    margin-left: 1.2rem;
  }
}
