.webplayer {
  margin-top: 5rem;

  .banner {
    background-color: $grey-c200;
    text-align: center;

    a {
      text-decoration: underline;
      color: $blueGrey-c500;
    }
  }

  .player-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 0;
    text-align: center;
    background-color: $green-c500;
    padding: 2rem 0;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: center;
    }

    .before-player {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 34rem;
      }

      @include media-breakpoint-up(lg) {
        width: 100%;
        justify-content: flex-end;
      }
    }
    .back {
      display: flex;
      align-items: center;
      color: $white-c500;
      text-transform: none;
      border: none;
      font-size: 1rem;
      padding: 0 2rem 0 0.5rem;
      cursor: pointer;

      .left-icon {
        margin-right: 0.5rem;
      }
    }
    .after-player {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }
    .right-image {
      height: 200px;
    }
  }
  .teaser-container {
    position: relative;
  }
}
