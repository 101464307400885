.gift-coupon-banner {
  white-space: pre-line;
  text-align: left;
  background: $blue-c500;
  color: $white-c500;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  max-height: 100%;
  padding: 1.75rem 1.25rem;

  &__headline {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
      line-height: 2.275rem;
    }
  }

  &__button {
    @include media-breakpoint-up(lg) {
      padding-left: 1.3rem !important;
      padding-right: 1.3rem !important;
    }
  }

  &__content-wrapper-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__illustration {
    width: 12rem;
    position: relative;
    left: -7rem;

    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
      left: 0;
    }
  }
}
