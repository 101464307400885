.br {
  &--mobile {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}

.hidden-md-up {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hidden-md-down {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
