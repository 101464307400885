@import 'player-list-item.scss';

$playerButtonBgSize-lg: 124px;
$playerButtonSize-lg: 106px;

$playerButtonBgSize-sm: 120px;
$playerButtonSize-sm: 100px;

$playerPositioning-lg: ($playerButtonBgSize-lg - $playerButtonSize-lg) * 0.5;
$playerPositioning-sm: ($playerButtonBgSize-sm - $playerButtonSize-sm) * 0.5;

.player {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background: $white-c500;
  border: solid 1px $white-c500;
  padding-top: 0;
  overflow: hidden;
  width: calc(100% - 2rem);
  margin: 0 1rem 1.8em;

  @include media-breakpoint-up(lg) {
    margin: 0;
    width: 100%;
    border: none;
    padding-top: 1rem;
  }

  @include media-breakpoint-up(sm) {
    width: 34rem;
    margin-bottom: 0;
  }

  .image {
    height: 12rem;
    object-fit: contain;
    padding-bottom: 2rem;
  }

  &-elements {
    display: flex;
    flex-direction: column;
    align-items: center;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    &__play-button-progress {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 100px;
      margin-bottom: 1em;
      height: $playerButtonBgSize-sm;
      width: $playerButtonBgSize-sm;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        height: $playerButtonBgSize-lg;
        width: $playerButtonBgSize-lg;
      }
      &-bar {
        .single-chart {
          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
        }
        .circle-bg {
          fill: black;
          opacity: 0.3;
          position: absolute;
          top: 0;
          left: 0;
        }
        .circle {
          fill: none;
          stroke-width: 4px;
        }

        .circular-chart .circle {
          opacity: 0.5;
          position: absolute;
        }
      }
    }

    &__play-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: $playerPositioning-sm;
      left: $playerPositioning-sm;
      width: $playerButtonSize-sm;
      height: $playerButtonSize-sm;
      background: $blue-c500;
      border-radius: 1000px;
      position: absolute;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        top: $playerPositioning-lg;
        left: $playerPositioning-lg;
        width: $playerButtonSize-lg;
        height: $playerButtonSize-lg;
      }

      &:hover {
        background: $blue-c600;
      }

      svg {
        height: 28%;
        width: auto;
      }
    }

    &-skip-buttons {
      display: flex;
      width: 13rem;
      align-items: center;
      justify-content: space-between;
      padding: 0em 1.5em 0em 1.5em;

      @include media-breakpoint-up(md) {
        width: 100%;
        padding: 0 0 1.5em;
      }

      &__skip {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.6em;
        height: 2.6em;

        svg {
          transform-origin: 50% 50%;
        }

        &:hover {
          cursor: pointer;
          background: $grey-c100;
          border-radius: 1000px;
        }
      }
      &__disabled {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.6em;
        height: 2.6em;

        svg {
          transform-origin: 50% 50%;
        }
      }
    }

    &__progressTime {
      font-size: 1.25em;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 0px;
      color: $blue-c500;

      /*
        Introduced in IE 10.
        See https://ie.microsoft.com/testdrive/HTML5/msUserSelect/
      */
      -ms-user-select: none;
      user-select: none;

      @include media-breakpoint-up(md) {
        font-size: 0.95em;
      }
    }
  }

  &-episode-list {
    font-size: 0.875;
    font-weight: 400;

    @include media-breakpoint-up(lg) {
      overflow-x: hidden;
    }
    &.single {
      overflow: hidden;
      height: 0;
    }
  }

  #play-icon {
    margin-left: 4px;
  }

  &--playing {
    .circle {
      animation-play-state: running;
    }

    #pause-icon {
      display: block;
    }

    #play-icon {
      display: none;
    }

    #locked-icon {
      display: none;
    }
  }

  &--pausing {
    .circle {
      animation-play-state: paused;
    }

    #pause-icon {
      display: none;
    }

    #play-icon {
      display: block;
    }

    #locked-icon {
      display: none;
    }
  }

  &--locked {
    .circle {
      animation-play-state: paused;
    }

    #pause-icon {
      display: none;
    }

    #play-icon {
      display: none;
    }

    #locked-icon {
      display: block;
    }
    .player-elements__play-button {
      &:hover {
        background: $blue-c500;
      }
    }
  }
}
