.checkbox-toggle-container {
  margin: 1rem 0;
  width: 100%;

  .checkbox-toggle {
    &__text-wrapper {
      align-self: stretch;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      display: flex;
      width: 100%;
    }

    &__text {
      font-size: 0.85rem;
      line-height: 1.3;
      text-align: left;
      padding: 0;
      margin: 0;
    }

    &__track {
      width: 40px;
      margin: 0 1.2rem 0.3rem 0;
    }
  }
}
