.testimonial {
  min-height: 200px;
  &__img-wrapper {
    display: flex;
    // use the maximum height of all images, so they will be vertically centered
    height: 50px;
  }

  &__img {
    display: block;
    margin: auto;
  }

  &__quote {
    margin: 0.5rem 0 1em 0;

    &::before {
      content: '„';
    }

    &::after {
      content: '“';
    }
  }

  p {
    font-size: 0.9rem;
    text-align: center;
  }
}
