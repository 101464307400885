.terminate-user-abo-success {
  .card-terminate {
    background-color: $grey-c100;

    @media (min-width: 768px) {
      border-radius: 10px;
      box-shadow: none;
    }

    .card-title {
      color: $green-c500;
    }
    .newsletter-disclaimer {
      font-size: 0.875rem;
      color: $grey-c800;
    }
    .date-hint {
      font-size: 12px;
      text-align: left;
    }
  }
  .card-footer {
    position: relative;
  }
  .ad-tile__illustration {
    max-width: 310px;
    width: 50%;
    transform: translateY(-6rem);
    @media (min-width: 768px) {
      transform: translateY(-11rem);
    }
  }
  .cta-download-pdf {
    max-height: 4rem;
    max-width: 28.75rem;
    border-radius: 3.125rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
