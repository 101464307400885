//nicht löschen, darüber werden die Stripe buttons im Checkout überschrieben, es hat nichts mit der CheckoutButton (GIFT) Component zu tun!

button.StripeCheckout {
  border: 0px !important;
  outline: none !important;
  background: $green-c600 !important;
  border-radius: 0 !important;
  border: 0px !important;
  box-shadow: none !important;
}

button.StripeCheckout span {
  display: flex !important;
  align-items: center;
  padding: 2em !important;
  background-color: $green-c600;
  outline: none !important;
  background-image: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  border: 0px !important;
  border-radius: 0px !important;
}
