.library-nav {
  display: block;
  padding-bottom: 20%;
  background-color: $grey-c200;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    position: absolute;
    top: 1.8rem;
    padding-bottom: 0rem;
  }

  ul {
    padding: 0 1.25rem;

    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
}
