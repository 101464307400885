.card {
  @include media-breakpoint-up(xs) {
    background-color: transparent;
    border-width: 0;
  }

  @include media-breakpoint-up(sm) {
    background-color: $white-c500;
    border-radius: 10px;
    @include card-shadow;
  }

  &-stoerer {
    @extend .card;

    &::before {
      content: '';
      background-image: url('../../images/google_payment/badge_caution.svg');
      position: absolute;
      top: -46px;
      left: calc(50% - 3rem);
      height: 5.8rem;
      width: 6rem;
      background-size: contain;
      background-repeat: no-repeat;

      @include media-breakpoint-down(lg) {
        width: 5.8rem;
      }

      @include media-breakpoint-down(md) {
        width: 5.8rem;
      }

      @include media-breakpoint-down(sm) {
        height: 6rem;
        width: 6rem;
      }

      @media (max-width: 575.98px) {
        height: 3.4rem;
        width: 100%;
        left: 0;
        top: -49px;
        background-position-x: center;
      }
    }
  }

  .card-important-hint {
    font-weight: 600;
    color: $green-c500;
    text-align: center;
    padding: 0;
    font-size: 1.8rem;
    letter-spacing: 0.9px;
  }

  .card-info-text {
    font-weight: 300;
    text-align: center;
    padding: 0 0 3rem 0;

    @include media-breakpoint-down(lg) {
      padding: 0 0 1.5rem 0;
    }

    @include media-breakpoint-down(md) {
      padding: 0 1.5rem 0.5rem 1.5rem;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 2rem 1.5rem 2rem;
    }

    @media (max-width: 575.98px) {
      padding: 0 0 0.5rem 0;
    }
  }

  .card-body,
  .card-header,
  .card-footer {
    background-color: transparent;
    border: 0;

    @include media-breakpoint-up(xs) {
      padding: 0;
    }

    @include media-breakpoint-up(sm) {
      padding: 1rem 2.5rem;
    }
  }

  .card-title {
    color: $green-c500;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
  }

  .card-footer {
    color: $green-c500;
    font-size: 12px;
    @include media-breakpoint-up(sm) {
      padding: 0rem 2rem;
    }

    @include media-breakpoint-up(xs) {
      margin-top: 0rem;
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      margin-top: 5rem;
      padding: 1rem 2.5rem;
    }
  }
}

.card-body {
  @include media-breakpoint-up(lg) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

.card-decorated {
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    display: inline-block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0;
  }

  &_top {
    &::before {
      content: '';
      @extend .balloon-gray-bg-top;
      top: -34px;
      right: -41px;
      width: 130px;
      height: 130px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &_bottom {
    &::after {
      content: '';
      @extend .balloon-gray-bg-bottom;
      bottom: -51px;
      left: -53px;
      width: 150px;
      height: 150px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
