.bkk {
  margin-top: 55px;

  h1 {
    font-weight: 500;
    font-size: 30px;

    @media (min-width: $breakPointMedium) {
      font-size: 45px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 60px;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 24px;

    @media (min-width: $breakPointMedium) {
      font-size: 36px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 48px;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 21px;

    @media (min-width: $breakPointMedium) {
      font-size: 21px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 21px;
    }
  }

  p {
    font-weight: 300;
    font-size: 18px;

    @media (min-width: $breakPointMedium) {
      font-size: 18px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 18px;
    }
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
    background-color: $blue-c500;
    background-image: url('../../../images/landingpages/bkk/hintergrund-illu.jpg');
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 422px;
    @media (max-width: 425px) {
      background-image: url('../../../images/landingpages/bkk/Hintergrundillustration_Mobil.jpg');
      background-position: bottom;
      background-size: contain;
    }
    @media (min-width: $breakPointLarge) {
      min-height: 500px;
    }
    .header {
      color: $white-c500;
      h1 {
        margin-top: 23px;
        padding: 0 250px;

        @media (min-width: $breakPointLarge) {
          margin-top: 20px;
          margin-bottom: 40px;
        }
        @media (max-width: 425px) {
          padding: 0px 20px;
        }
      }

      h3 {
        margin-top: 12px;
        font-weight: 300;
        padding: 0 250px;

        @media (max-width: 850px) {
          padding: 0 2.5rem;
        }

        @media (min-width: $breakPointLarge) {
          margin-top: 10px;
          padding: 0 450px;
        }
        @media (max-width: 325px) {
          padding: 0 20px;
        }
      }
    }
  }

  .blue-line-container {
    height: 100%;
    width: 102%;
    position: relative;
    top: -1px;
    left: -2px;
    overflow: hidden;

    @media (max-width: 425px) {
      top: -3px;
    }
    @media (max-width: 325px) {
      top: -5px;
    }
    &__ballooni-bottom {
      top: 2px;
    }
  }

  .blue-line {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  #checkmark-section-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: $white-c500;

    #content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: $breakPointMedium) {
        padding-left: 60px;
        padding-right: 60px;
      }

      @media (min-width: 955px) {
        padding-left: 285px;
        padding-right: 250px;
      }
    }

    h2 {
      margin-top: 33px;
      margin-bottom: 7px;
      align-self: center;
      color: $blue-c500;

      @media (max-width: 425px) {
        font-size: 30px;
      }
      @media (min-width: $breakPointMedium) {
        margin-top: 79px;
        margin-bottom: 27px;
      }
    }

    #bottom-container {
      display: flex;
      flex-direction: column;

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
      }

      #text-container {
        width: 100%;
        margin-right: 0;

        @media (min-width: $breakPointLarge) {
          width: 50%;
          margin-right: 50px;
        }

        .checkmark-container {
          margin-top: 50px;
          @media (min-width: $breakPointMedium) {
            margin-bottom: 50px;
          }
          .checkmark-content {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            align-items: center;

            .img-checkmark {
              margin-right: 15px;
            }
          }
        }
      }

      #link-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 0;

        @media (min-width: $breakPointLarge) {
          width: 50%;
        }

        .img-course-phone {
          width: 100%;
          height: auto;
          @media (max-width: $breakPointMedium) {
            max-width: 385px;
            margin: 0 auto;
          }
        }
      }
    }
  }

  #ballooni-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    background-color: $blue-c500;

    @media (min-width: $breakPointLarge) {
      margin-bottom: 0;
    }

    h2 {
      margin: 0 35px;
      margin-top: 30px;
      color: $white-c500;
      text-align: center;

      @media (max-width: 425px) {
        margin: 0px 30px;
        margin-top: 30px;
      }

      @media (min-width: $breakPointMedium) {
        margin: 0 55px;
      }
      @media (min-width: $breakPointLarge) {
        margin-top: 0px;
        margin: 0 280px;
      }
    }

    #bottom-container {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      padding: 0 250px;

      @media (max-width: 850px) {
        padding: 0 2.5rem;
      }

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
        margin-top: 25px;
      }

      .ballooni-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 23px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;

        @media (min-width: $breakPointMedium) {
          padding-left: 80px;
          padding-right: 80px;
        }

        @media (min-width: $breakPointLarge) {
          width: 414px;
          margin-top: 0;
          margin-left: 15px;
          margin-right: 15px;
        }

        h4 {
          margin-top: 20px;
        }

        p {
          color: $white-c500;
          margin-top: 15px;
        }

        a {
          color: $white-c500;
          text-decoration: underline;
        }
      }

      .ballooni1-image,
      .ballooni2-image,
      .ballooni3-image {
        width: 180px;
        height: 180px;

        @media (min-width: $breakPointMedium) {
          width: 200px;
          height: 200px;
        }
      }

      .ballooni1-image {
        background: url('../../../images/landingpages/b2b_clients//illu-zugangs-code.jpg') no-repeat;
        background-size: cover;
      }

      .ballooni2-image {
        background: url('../../../images/landingpages/b2b_clients//illu-registrierung.jpg') no-repeat;
        background-size: cover;
      }

      .ballooni3-image {
        background: url('../../../images/landingpages/b2b_clients//illu-kurs.jpg') no-repeat;
        background-size: cover;
      }
    }
  }

  #form-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media (min-width: $breakPointMedium) {
      &::before {
        content: '';
        background-image: url('../../../images/background_circle_down_left.svg');
        width: 365px;
        height: 506px;
        position: absolute;
        top: 368px;
        left: -192px;
        z-index: 0;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &::after {
        content: '';
        background-image: url('../../../images/background_circle_up_right.svg');
        width: 186px;
        height: 246px;
        position: absolute;
        top: 200px;
        right: 0;
        z-index: 0;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    h2 {
      margin-top: 42px;

      @media (min-width: $breakPointLarge) {
        margin-top: 65px;
      }
    }
    h3 {
      @media (min-width: $breakPointMedium) {
        text-align: center;
      }
    }

    #form-box-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 15px;
      margin-bottom: 45px;
      padding-left: 20px;
      padding-right: 20px;

      input {
        width: 50%;
        margin: auto;
        border-radius: 10px;
        @media (max-width: 425px) {
          width: 100%;
        }
      }

      .form-check-label {
        width: 50%;
        text-align: left;
      }
      .btn-secondary {
        width: 50%;
        text-align: center;
        margin: 0 auto;
      }

      @media (min-width: $breakPointMedium) {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
        margin-top: 55px;
        margin-bottom: 95px;
        padding-left: 0;
        padding-right: 0;
      }

      .logo-container {
        display: flex;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .form-box {
        border-radius: 10px;
        box-shadow: 0 0 7px 0 $black-o30;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: $white-c500;
        padding: 18px;
        padding-bottom: 27px;
        margin-top: 16px;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        border: solid 1px $white-c500;
        z-index: 10;

        @media (min-width: $breakPointMedium) {
          margin-top: 30px;
        }

        @media (min-width: $breakPointLarge) {
          border-radius: 20px;
          box-shadow: 0 0 15px 0 $black-o30;
          width: 750px;
          padding: 22px;
          padding-bottom: 37px;
          margin-top: 0;
          margin-left: 20px;
          margin-right: 20px;
          border: solid 2px $white-c500;
        }

        p {
          font-size: 12px;
          margin-top: 3px;
          margin-bottom: 3px;
          text-align: center;
          padding: 0 10px;

          @media (min-width: $breakPointMedium) {
            font-size: 16px;
          }

          @media (min-width: $breakPointLarge) {
            font-size: 18px;
            margin-top: 5px;
            margin-bottom: 13px;
            padding: 0px 20px;
          }
        }

        h3 {
          font-weight: 300;
          font-size: 18px;

          @media (min-width: $breakPointMedium) {
            font-size: 24px;
            text-align: center;
          }

          @media (min-width: $breakPointLarge) {
            font-size: 36px;
          }
        }

        h4 {
          margin-top: 0;
          margin-bottom: 5px;

          @media (min-width: $breakPointLarge) {
            margin-top: 4px;
            margin-bottom: 6px;
          }
        }
        #form-register-section-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          margin-bottom: 45px;
          padding-left: 20px;
          h2 {
            color: $green-c500;
            text-align: center;
            font-size: 35px;
            font-weight: 500;
            margin-top: 0px;
            line-height: 1.3;

            @media (max-width: 425px) {
              font-size: 25px;
            }
          }
          .anmeldedaten {
            text-align: center;
            margin: 20px 0;
          }
          form {
            margin-top: 30px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-width: 282px;
            text-align: center;
          }
          .card-social {
            background-color: white;
            text-align: center;
            padding: 0 50px 20px;
          }
        }

        .card-link,
        a {
          color: $green-c500;
          font-size: 13px;
        }
        a {
          text-decoration: underline;
          cursor: pointer;
        }

        .plans-btn {
          border-radius: 17.5px;
          border: solid 2px $blue-c500;
          padding: 40px 23px 24px 26px;
          @media (min-width: $breakPointMedium) {
            width: 60%;
          }
          margin: 0 auto;
          .plans-btn__title {
            font-size: 1rem;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: $blue-c800;
          }
          .plans-btn__relator {
            font-size: 1rem;
            padding: 18px 0 26px;
            display: block;
          }
          .plan-btn-bkk {
            width: 100%;
            margin: 0 auto;
          }
          .plans-btn__amount {
            font-size: 2.5rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $blueGrey-c200;
          }
        }
        .subscription-disclaimer {
          font-size: 0.7rem;
          font-weight: 300;
          text-align: center;
          line-height: 1.62;
          color: $grey-c600;
        }

        .form-check-input {
          width: 1.3em;
          height: 1.3em;
          background-color: $grey-c100;
          border-radius: 50%;
          border: solid 0.2em $white-c500;
          -webkit-appearance: none;
          outline: none;
          cursor: pointer;
          float: unset;
        }

        label {
          font-size: 13px;
          margin-left: 15px;
        }

        input[type='checkbox']:checked {
          background-color: $green-c500;
        }

        .bkk-success-content {
          img {
            width: 100%;
          }
          .app-badge {
            justify-content: space-evenly;
            margin: auto;
          }
          a {
            color: $white-c500;
            text-decoration: none;
            @include media-breakpoint-down(md) {
              margin-bottom: 40px;
            }
          }
          .content {
            text-align: center;
            padding: 0 80px;
          }
        }

        &.reset-bottom {
          padding-bottom: 0px;
          border-bottom: 0;
        }
      }

      .code-entry {
        min-height: 420px;
        @include media-breakpoint-down(md) {
          min-height: 465px;
        }

        @media (max-width: 365px) {
          min-height: 600px;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          &:focus {
            outline: $green-c500 auto 1px;
          }
        }

        .btn-resend-code {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
        .sign-in-user-with-onetime-password {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .fb-error {
            text-align: center;
          }
          .btn-secondary {
            margin: 15px 0 0 0;
          }
        }
      }
    }
  }
  .profile-subheading {
    font-size: 1.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: center;
    color: $blue-c500;
  }
  .profile-subline {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: $blueGrey-c500;
    padding: 0px 0.5rem;
  }

  .giftcoupon-form button {
    margin: 0;
    border-color: $green-c500;
    background-color: $green-c500;
  }
  .fb-error {
    display: block;
    width: 50%;
    margin-top: 4px;
    font-size: 80%;
    color: $error-c500;
    text-align: left;
    margin: 0 auto;
  }
}
