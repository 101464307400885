.xing-modal {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1050;
  width: 90%;
  height: 90%;
  opacity: 1;
  transition: opacity 0.5s ease-in 1.5s;
  visibility: visible;
  &--hide {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    visibility: hidden;
  }
  .close {
    position: fixed;
    right: 1rem;
    z-index: 3;
  }
  .card {
    -webkit-box-shadow: 0px 0px 60px 0px $black-c500;
    -moz-box-shadow: 0px 0px 60px 0px $black-c500;
    box-shadow: 0px 0px 60px 0px $black-c500;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 8px;
    background-color: $white-c500;
    max-height: 100%;

    .card-header {
      padding: 0 1rem 2rem 0rem;
      overflow: initial;
      position: relative;
      .cricleTop {
        border-radius: 10px;
        position: relative;
        left: -1px;
      }
    }
    .subtitle {
      font-family: 'Poppins';
      font-size: 1.5rem;
      font-weight: $font-weight-semibold;
      @media (max-width: 575.98px) {
        font-size: 1.125rem;
      }
    }
    .card-footer {
      position: relative;

      .card-link {
        font-family: 'Poppins';
        font-size: 0.625rem;
        color: $blue-c600;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: center;
      }

      .balloonie-cool {
        position: absolute;
        right: -1px;
        bottom: 0;
        width: 215px;
        height: auto;
        @media (max-width: 2460px) and (min-width: 1705px) {
          width: 190px;
        }
        @media (max-width: 1440.98px) {
          width: 190px;
        }
        @media (max-width: 768.98px) {
          width: 180px;
        }
      }
    }
  }
  .plans-btn__title {
    font-size: 2rem;
    font-weight: $font-weight-semibold;
    line-height: 1.22;
    letter-spacing: 0.67px;
    margin-bottom: 0rem;
    text-align: center;
    @media (max-width: 575.98px) {
      line-height: 2rem;
      padding: 0;
      margin-bottom: 0;
      font-size: 1.7rem;
    }
  }
}
