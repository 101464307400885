.library-nav-item {
  display: table;
  padding-bottom: 1px;
  margin-bottom: 0.6em;
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  list-style-type: none;

  a {
    color: $blueGrey-c500 !important;
  }

  cursor: pointer;
  padding-left: 1rem;

  @include media-breakpoint-up(md) {
    padding-left: 2.7rem;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 1.3rem;
  }

  &--active {
    font-weight: 500;
  }

  &--default {
    border-bottom: none;
  }

  &--headline {
    font-weight: 400;
    box-shadow: inset 0px -2px 0 0 $grey-c500;
    border-bottom: none;
  }

  &--top {
    margin-bottom: 0 !important;
  }
}
