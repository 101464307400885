.terminate-user-abo {

  .terminate-created-at-formfield {
    input {
      background-color: $white-c500 !important;
    }
    .mb-3 {
      margin-bottom: 0 !important;
    }
  }

  .card-terminate {
    background-color: $grey-c100;

    @media (min-width: 768px) {
      border-radius: 10px;
      box-shadow: none;
    }

    .card-title {
      color: $green-c500;
      float: left;
    }

    .newsletter-disclaimer,
    label {
      font-size: 0.875rem;
      color: $grey-c800;
    }

    .date-hint {
      font-size: 12px;
      text-align: left;
      padding-left: 5px;
    }
  }
  .card-footer {
    position: relative;
  }
  .ad-tile__illustration {
    max-width: 310px;
    width: 50%;
    transform: translateY(-6rem);
    @media (min-width: 768px) {
      transform: translateY(-11rem);
    }
  }

  .terminate-user-abo-form {
    input,
    select,
    textarea {
      background-color: $white-c500;
      border-radius: 8px;
    }
  }
}
