.card-info {
  position: relative;
  padding: 3rem;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(lg) {
    padding: 3rem 4rem 4rem 4rem;
  }

  @include media-breakpoint-down(md) {
    padding: 2.5rem 3rem 3rem 3rem;
  }

  @media (max-width: 575.98px) {
    padding: 1rem 0 0 0;
  }

  .card-footer {
    padding: 0;
    margin: 2rem 0 0 0;

    @include media-breakpoint-down(lg) {
      margin: 1rem 0 0 0;
    }

    @include media-breakpoint-down(md) {
      margin: 1rem 0 0 0;
    }

    @media (max-width: 575.98px) {
      margin: 0.8rem 0 0 0;
    }
  }

  .card-body {
    font-weight: 300;
    padding: 0;
    margin: 0;
  }
}

.thankyou {
  margin-bottom: 3rem;

  &__heading {
    text-align: center;
    font-weight: 300;
    font-size: 1.875;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      font-size: 2.7rem;
      font-weight: 300;
      margin-bottom: 3rem;
    }

    @media (max-width: 575.98px) {
      font-size: 1rem;
      font-weight: 300;
      margin-bottom: 2rem;
      border-bottom: 1px solid $grey-c800;
    }
  }

  &__subheading {
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 2rem;

    @media (max-width: 575.98px) {
      display: block;
      margin-bottom: 1.3rem;
    }
  }
}
