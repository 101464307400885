.w-65 {
  width: 65% !important;
}

.background {
  &-white {
    &-c500 {
      background-color: $white-c500;
    }
  }
}

.form-input {
}

.mime-elements-button .spinner {
  top: -24px;
}
