// The approach to style Checkboxes and radio buttons
// is based on the explanation in Lea Verou's "CSS Secrets"
// (p. 228ff)

.checkout {
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);

    + label::before {
      content: '\a0'; // non-break space
      display: inline-block;
      width: 1.2em;
      height: 1.2em;
      margin-right: 0.7em;
      background: $grey-c200;
      line-height: 1;
      vertical-align: 0.2em;
    }

    &:focus {
      + label::before {
        box-shadow: $input-btn-focus-box-shadow;
      }
    }
  }

  input[type='radio'] + label::before {
    border: solid 0.2em $white-c500;
    border-radius: 50%;
    vertical-align: -0.1em;
  }

  input[type='checkbox']:checked + label {
    position: relative;
  }

  input[type='radio']:checked + label::before {
    background-color: $green-c500;
  }

  input[type='checkbox']:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    top: 0.05em;
    left: 0.4em;
    width: 0.4em;
    height: 1em;
    transform: rotate(45deg);
    border: solid $green-c500;
    border-width: 0 0.23em 0.23em 0;
  }

  // input[type="checkbox"]:focus + label::before,
  // input[type="radio"]:focus + label::before {
  //   box-shadow: 0 0 0.1em 0.1em $blue-c500;
  // }

  input[type='checkbox']:disabled + label::before,
  input[type='radio']:disabled + label::before {
    background-color: $grey-c500;
    box-shadow: none;
  }
}
