.form-header {
  width: 90%;

  &__headline {
    text-align: center;
    color: $green-c500;
    margin: 2rem 0 0.8rem;

    @media (min-width: $breakPointMedium) {
      margin: 3.25rem 0 1rem;
    }

    @media (min-width: $breakPointLarge) {
      margin: 3.25rem 0 1rem;
    }
  }

  &__infotext {
    font-weight: 300;
    text-align: center;
    color: $black-c500;
    margin-bottom: 2rem !important;

    @media (min-width: $breakPointMedium) {
      margin: 0 5rem 1rem !important;
    }
  }

  &__link {
    color: $black-c500;
  }
}
