.payment-error {
  color: $grey-c800;
  font-size: 0.9rem;
  letter-spacing: 0.8px;

  &__card {
    padding: 3.5rem 4rem 3rem 4rem;
    height: auto;

    @include media-breakpoint-down(md) {
      padding: 3rem 1rem 2.5rem 1rem;
    }
    @include media-breakpoint-down(sm) {
      padding: 3rem 1rem 2.5rem 1rem;
    }

    @media (max-width: 575.98px) {
      padding: 2rem 0.5rem 1.8rem 0.5rem;
    }
  }

  img {
    width: 30%;
    margin-top: 2rem;
    margin-bottom: 3rem;

    @include media-breakpoint-down(sm) {
      width: 35%;
    }

    @media (max-width: 575.98px) {
      width: 40%;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }
}
