.player-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0;

  &__title {
    font-size: 1.25rem;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    letter-spacing: 0.02em;
    padding-top: 1rem;

    @include media-breakpoint-only(md) {
      padding-top: 2rem;
    }

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }
}
