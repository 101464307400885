.library-teaser-card {
  text-align: center;
  padding: 1.25em;
  margin-bottom: 1.8em;
  text-align: center;

  @media (max-width: 575.98px) {
    .btn {
      min-width: 230px;
    }
  }

  @include media-breakpoint-up(xs) {
    margin-bottom: 3em;
  }

  @include media-breakpoint-up(md) {
    background: $grey-c100;
  }

  @include media-breakpoint-up(lg) {
    padding: 2em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 3.8em 2.5rem 3.5rem 2.5rem;
  }

  &__text {
    margin-bottom: 1rem;
  }
}
