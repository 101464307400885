.payment-method-panel {
  tr {
    padding: 0;
  }
  td {
    padding: 0.1rem 0;

    &:first-child {
      width: 30%;
    }
  }
}
