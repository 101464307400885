$border-radius: 0.5rem;

.library-initial-item {
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-start;
  position: relative;
  margin-bottom: 1.5em;
  width: 100%;
  background-color: $white-c500;
  overflow: hidden;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 0.5em;

    &::before {
      display: block;
      justify-content: center;
      align-content: center;
      height: 30px;
      width: 30px;
    }
  }

  &__illustration-wrapper {
    position: relative;
    min-height: 11.313rem;
    width: 100%;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @media (min-width: 375px) {
      min-height: 12.313rem;
    }

    @media (min-width: 425px) {
      min-height: 13.313rem;
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 1;
    justify-content: space-between;
    padding: 1.125rem;

    @include media-breakpoint-up(lg) {
      align-items: space-between;
      width: 50%;
      padding: 1.875rem;
    }
  }

  .btn.btn-grey-c100 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    border-radius: $border-radius;
    transition: color 0.15s ease-in-out, fill 0.15s ease-in-out;
    border: none;

    &:hover {
      background: $white-c500;
    }

    svg {
      margin-right: 1em;
    }
    path {
      display: inline-block;
    }
  }

  &__headline {
    width: 100%;
    font-size: 1.25rem;
    max-height: 4.375rem;
    letter-spacing: 0.02em;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    line-height: 1.42;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;

    @include media-breakpoint-up(md) {
      max-height: 3.6rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
      max-height: 4.3rem;
    }
  }

  &__sub-headline {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0.313rem;
    line-height: 1.563rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
    }
  }

  &__description {
    max-height: 3.1rem;
  }

  &--course {
    height: 26.375rem;

    @media (min-width: 375px) {
      height: 27.375rem;
    }

    @media (min-width: 425px) {
      height: 28.375rem;
    }

    @include media-breakpoint-up(md) {
      height: 15rem;
    }

    @include media-breakpoint-up(lg) {
      height: 18.125rem;
    }
  }
}
