.header-marketing {
  position: relative;
  background-color: $green-c500;
  color: $white-c500;
  padding-bottom: 9.5rem;

  @include media-breakpoint-up(md) {
    background-image: url('../../../../images/landingpages/health_insurance_marketing/header_balloonie.png');
    background-repeat: no-repeat;
    background-position: right 50%;
    background-size: 38rem;
    padding-bottom: unset;
  }

  @include media-breakpoint-up(xl) {
    min-height: 30rem;
    background-size: 50rem;
  }

  &__balloonie {
    width: 100%;
    margin-bottom: -1.5rem;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__content-container {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      margin: 0 0 8rem;
    }
  }

  &__button-row {
    display: flex;
    flex-direction: column;
  }

  &__seal {
    position: absolute;
    bottom: -2.5rem;
    left: 50%;
    transform: translateX(calc(-50%));
    height: 10.5rem;

    @include media-breakpoint-up(md) {
      transform: translateX(calc(-50% + 200px));
    }
  }
}
