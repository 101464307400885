.course-teaser {
  margin-bottom: 4em;

  @include media-breakpoint-up(lg) {
    &::before {
      content: '';
      background-image: url('../../images/background_circle_down_left.svg');
      width: 365px;
      height: 596px;
      position: absolute;
      top: 0;
      left: -192px;
      z-index: -2;
      background-size: contain;
      background-repeat: no-repeat;
      transform: scale(0.5);
    }
    &::after {
      content: '';
      background-image: url('../../images/background_circle_up_right.svg');
      width: 186px;
      height: 246px;
      position: absolute;
      top: 0;
      right: -70px;
      z-index: -1;
      background-size: contain;
      background-repeat: no-repeat;
      transform: scale(0.5);
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(lg) {
      font-size: 1.25rem;
    }
  }

  > a:hover {
    text-decoration: none;
  }
}
