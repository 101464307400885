.audio-player-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: auto;

  .modal-content {
    display: flex;
    align-self: center;
    border: 1px solid $pink-c500;
    margin: auto;
    background-color: $pink-c500;
  }

  &__img {
    background-color: $pink-c500;
    max-height: 90%;
    width: 90%;
    aspect-ratio: 300/400;
  }

  &__body {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10%;
  }

  &__close-btn {
    position: absolute;
    z-index: 1;
    top: 0.3rem;
    right: 0.8rem;
  }

  &__player {
    audio {
      background-color: $pink-c500;
      border-radius: 8px;
      border: 1px solid $pink-c500;
      color: $white-c500;
    }
  }
}
