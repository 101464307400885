.trust-band-section-b2b {
  padding: 2.5rem 0 0;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  display: none;

  @media (min-width: $breakPointMedium) {
    display: flex;
  }

  h4 {
    font-size: 21px;
    font-weight: 400;
  }

  .logo-container {
    display: flex;
    width: 100%;
    align-items: space-between;
    justify-content: center;
    flex-direction: row;
    align-self: center;

    @media (min-width: $breakPointMedium) {
      width: 1000px;
    }

    &__logo {
      width: 18%;
    }
  }
}
