.library {
  overflow: visible;
  .library-nav-col {
    height: 9rem;
    background-color: $white-c500;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 5;

    @include media-breakpoint-up(md) {
      height: 9.5rem;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      background-color: transparent;
      margin-top: 0;
      height: 100%;
      z-index: 1;
    }
  }

  .fixed-top {
    @include media-breakpoint-up(lg) {
      position: static;
    }
  }

  .library-content-col {
    margin-top: 4.8rem;
    background-color: none;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
      z-index: 0;
    }
  }

  .library-nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1.15rem;
    height: 4rem;
    background-color: $grey-c200;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      height: 4.5rem;
      padding-top: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      height: unset;
      background-color: transparent;
    }

    &--collapse {
      overflow-y: scroll;
      max-height: 90vh;

      &::-webkit-scrollbar {
        width: 0.1em;
        background-color: $white-c500;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $black-c500;
      }

      @include media-breakpoint-up(md) {
        overflow-y: visible;
        height: 100%;
        padding-bottom: 0;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $white-c500;
      }
    }

    .navbar-toggler-icon {
      background-image: url('../../images/navigation/menu_arrow-right.svg');
      width: 2.5rem;
    }

    .navbar-toggler {
      transition: transform 0.3s ease;
      margin: -0.9rem 0 0 0;
      border: none;

      @include media-breakpoint-up(md) {
        margin-right: 1.5rem;
      }

      &--collapsed {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
      }
    }
  }
}
