.xing-pro-jobs {
  margin-top: 55px;
  position: relative;

  h1 {
    font-weight: 500;
    font-size: 30px;

    @media (min-width: $breakPointMedium) {
      font-size: 45px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 50px;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 24px;

    @media (min-width: $breakPointMedium) {
      font-size: 36px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 48px;
    }
  }

  h3 {
    font-weight: 500;
    font-size: 21px;

    @media (min-width: $breakPointMedium) {
      font-size: 21px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 21px;
    }
  }

  p {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 1rem;

    @media (min-width: $breakPointMedium) {
      font-size: 18px;
      margin-bottom: 0;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  .header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
    background-color: $green-c500;
    background-image: url('../../../images/landingpages/xing_pro_jobs/illustration@3x.png');
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 422px;
    @media (max-width: 425px) {
      background-image: url('../../../images/landingpages/xing_pro_jobs/illustration-stadt-mobile@3x.png');
      background-position: bottom;
      background-size: contain;
      height: 550px;
    }
    @media (max-width: $breakPointMedium) {
      background-position: bottom;
      background-size: contain;
    }
    @media (min-width: $breakPointLarge) {
      justify-content: center;
      min-height: 500px;
      background-position: left center;
      background-size: 1400px 500px;
    }
    .header {
      color: $white-c500;
      h1 {
        margin-top: 23px;
        padding: 0 1.5rem;

        @media (min-width: $breakPointLarge) {
          margin-top: 20px;
          margin-bottom: 40px;
          padding: 0 14rem;
        }
      }

      h3 {
        margin-top: 12px;
        font-weight: 300;
        padding: 0 1.5rem;
        @media (min-width: $breakPointLarge) {
          margin-top: 10px;
          padding: 0 23rem;
        }
      }
    }
  }

  .header-line-container {
    height: 37px;
    width: 100%;
    position: relative;
    background-color: $green-c500;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .blue-line-container {
    height: 100%;
    width: 102%;
    position: relative;
    top: -1px;
    left: -2px;
    overflow: hidden;

    @media (max-width: 425px) {
      top: -3px;
    }
    @media (max-width: 325px) {
      top: -5px;
    }
    &__ballooni-bottom {
      top: 2px;
    }
  }

  .blue-line {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  #checkmark-section-container {
    background-color: $white-c500;

    #content-container {
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: $breakPointMedium) {
        padding-left: 60px;
        padding-right: 60px;
      }

      @media (min-width: 1120px) and (max-width: 1220px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      @media (min-width: 1415px) {
        padding-left: 180px;
        padding-right: 150px;
      }
    }

    h2 {
      margin-top: 33px;
      margin-bottom: 7px;
      align-self: center;
      color: $green-c500;

      @media (max-width: 425px) {
        font-size: 30px;
      }
      @media (min-width: $breakPointMedium) {
        margin-top: 79px;
        margin-bottom: 27px;
      }
    }

    #bottom-container {
      display: flex;
      flex-direction: column;

      @media (min-width: 1025px) {
        flex-direction: row;
      }

      #text-container {
        width: 100%;
        margin-right: 0;

        @media (min-width: 1025px) {
          width: 50%;
          margin-right: 50px;
        }
        @media (min-width: 1120px) and (max-width: 1220px) {
          margin-right: 3rem;
          padding-left: 6rem;
        }

        @media (min-width: 1221px) {
          margin-right: 9rem;
        }

        .checkmark-container {
          margin-top: 30px;
          @media (min-width: $breakPointMedium) {
            margin-bottom: 30px;
          }
          .checkmark-content {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            align-items: center;

            .img-checkmark,
            .img-checkmark-plus {
              margin-right: 15px;
            }

            .img-checkmark-plus {
              align-self: flex-start;
            }
          }
        }
      }

      #link-container {
        width: 100%;
        margin-bottom: 0;
        display: flex;
        justify-content: center;

        @media (min-width: $breakPointLarge) {
          width: 50%;
        }

        .img-course-phone {
          width: 100%;
          height: auto;
          object-fit: contain;

          &_mobil {
            display: none;
          }

          &_desktop {
            display: none;
          }
          @media (max-width: 1024px) {
            max-width: 385px;
            margin: 0 auto;

            &_mobil {
              display: block;
            }
          }
          @media (min-width: 1025px) {
            &_desktop {
              display: block;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  #ballooni-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    background-color: $blue-c500;

    @media (min-width: $breakPointLarge) {
      margin-bottom: 0;
    }

    h1 {
      margin: 0 35px;
      margin-top: 30px;
      color: $white-c500;
      text-align: center;

      @media (max-width: 425px) {
        margin: 0px 30px;
        margin-top: 30px;
      }

      @media (min-width: $breakPointMedium) {
        margin: 0 55px;
      }
      @media (min-width: $breakPointLarge) {
        margin-top: 0px;
        margin: 0 280px;
        padding: 0px 1rem;
      }
    }

    #bottom-container {
      display: flex;
      flex-direction: column;
      margin-top: 0px;
      padding: 0 25px;

      @media (max-width: 850px) {
        padding: 0 50px;
      }

      @media (min-width: $breakPointLarge) {
        padding: 0 250px;
        margin-top: 40px;
        flex-direction: row;
        margin-top: 25px;
      }

      .ballooni-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;

        @media (min-width: $breakPointMedium) {
          margin-top: 23px;
          padding: 0 80px;
        }

        @media (min-width: $breakPointLarge) {
          width: 354px;
          padding: 0 45px;
          margin-top: 0;
          margin-left: 0px;
          margin-right: 0px;
        }

        h4 {
          margin-top: 20px;
        }

        p {
          color: $white-c500;
          margin-top: 15px;
        }
      }

      .ballooni1-image,
      .ballooni2-image,
      .ballooni3-image {
        width: 180px;
        height: 180px;

        @media (min-width: $breakPointMedium) {
          width: 200px;
          height: 200px;
        }
      }

      .ballooni1-image {
        background: url('../../../images/landingpages/b2b_clients//illu-zugangs-code.jpg') no-repeat;
        background-size: cover;
      }

      .ballooni2-image {
        background: url('../../../images/landingpages/b2b_clients//illu-registrierung.jpg') no-repeat;
        background-size: cover;
      }

      .ballooni3-image {
        background: url('../../../images/landingpages/b2b_clients//illu-kurs.jpg') no-repeat;
        background-size: cover;
      }
    }
  }

  #form-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    @media (min-width: $breakPointMedium) {
      &::before {
        content: '';
        background-image: url('../../../images/background_circle_down_left.svg');
        width: 365px;
        height: 506px;
        position: absolute;
        top: 368px;
        left: -192px;
        z-index: 0;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &::after {
        content: '';
        background-image: url('../../../images/background_circle_up_right.svg');
        width: 186px;
        height: 246px;
        position: absolute;
        top: 200px;
        right: 0;
        z-index: 0;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    h2 {
      margin-top: 42px;

      @media (min-width: $breakPointLarge) {
        margin-top: 65px;
      }
    }
    h3 {
      @media (min-width: $breakPointMedium) {
        text-align: center;
      }
    }

    #form-box-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 20px;
      padding-right: 20px;
      z-index: 2;

      input {
        width: 100%;
        margin: auto;
        border-radius: 10px;
      }

      .form-check-label {
        width: 50%;
        text-align: left;
      }
      .btn-secondary {
        width: 100%;
        text-align: center;
        margin: 0 auto;
      }

      @media (min-width: $breakPointMedium) {
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 15px;
        margin-bottom: 45px;
      }

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
        margin-top: 55px;
        margin-bottom: 95px;
        padding-left: 0;
        padding-right: 0;
      }

      .logo-container {
        display: flex;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .form-box {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: $white-c500;
        padding: 27px 18px 18px 10px;
        margin-top: 5px;
        margin-left: 0;
        margin-right: 0;
        position: relative;

        @media (min-width: $breakPointMedium) {
          border: solid 1px $white-c500;
          border-radius: 10px;
          box-shadow: 0 0 7px 0 $black-o30;
          margin-top: 30px;
        }

        @media (min-width: $breakPointLarge) {
          border-radius: 20px;
          box-shadow: 0 0 15px 0 $black-o30;
          width: 750px;
          padding: 22px;
          padding-bottom: 37px;
          margin-top: 0;
          margin-left: 20px;
          margin-right: 20px;
          border: solid 2px $white-c500;
        }

        p {
          font-size: 12px;
          margin-top: 3px;
          margin-bottom: 3px;
          text-align: center;
          padding: 0 10px;

          @media (min-width: $breakPointMedium) {
            font-size: 16px;
            padding: 0px 80px;
          }

          @media (min-width: $breakPointLarge) {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 13px;
            padding: 0px 120px;
          }
        }

        h3 {
          font-weight: 300;
          font-size: 18px;

          @media (min-width: $breakPointMedium) {
            font-size: 24px;
            text-align: center;
          }

          @media (min-width: $breakPointLarge) {
            font-size: 36px;
          }
        }

        h4 {
          margin-top: 0;
          margin-bottom: 5px;

          @media (min-width: $breakPointLarge) {
            margin-top: 4px;
            margin-bottom: 6px;
          }
        }

        #form-register-section-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          margin-bottom: 45px;

          .wrapper {
            padding: 0 0;
            @media (min-width: $breakPointMedium) {
              padding: 0 15%;
            }
          }

          .extra-small.legal-newsletter-info {
            border-top: 1px dotted $grey-c800;
            color: $grey-c800;
            margin: 18px 0 24px;
            padding: 20px 16px 0 4px;
          }

          h2 {
            color: $green-c500;
            text-align: center;
            font-size: 25px;
            font-weight: 500;
            margin-top: 0px;
            line-height: 1.3;

            @media (min-width: 425px) {
              font-size: 30px;
              padding: 0 85px;
            }
            @media (min-width: $breakPointLarge) {
              padding: 0px 120px;
              margin-top: 5px;
              margin-bottom: 35px;
            }
          }
          .anmeldedaten {
            text-align: center;
            margin: 20px 0;
          }
          form {
            margin-top: 30px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            min-width: 282px;
            text-align: center;
            @media (min-width: $breakPointMedium) {
              min-width: 470px;
            }
            &.login-form {
              margin-bottom: 0;
              .form-group {
                padding: 0 3rem;
              }
              .btn-secondary {
                margin-bottom: 1rem;
                width: 75%;
              }
            }
            @media (min-width: $breakPointMedium) {
              &.login-form {
                margin-bottom: 0;
                .form-group {
                  padding: 0 7.5rem;
                }
                .btn-secondary {
                  width: 65%;
                }
              }
            }
          }
          .card-social {
            background-color: $white-c500;
            text-align: center;
            padding: 0 50px 20px;
            grid-row-gap: 20px;
            grid-template-rows: auto;
            p {
              margin-bottom: 0;
              height: 20px;
            }
            @media (min-width: $breakPointMedium) {
              width: 80%;
              grid-row-gap: 20px;
              grid-template-rows: auto;
              p {
                margin-bottom: 0;
                height: 20px;
              }
            }
            @media (min-width: 800px) and (max-width: $breakPointLarge) {
              width: 55%;
            }
          }
        }

        .card-link {
          color: $green-c500;
          font-size: 13px;
        }
        a {
          text-decoration: underline;
          cursor: pointer;
        }

        label {
          font-size: 13px;
          margin-left: 32px;
        }

        input[type='checkbox']:checked {
          background-color: $green-c500;
        }

        .bkk-success-content {
          img {
            width: 100%;
          }
          .app-badge {
            justify-content: space-evenly;
            margin: auto;
          }
          a {
            color: $white-c500;
            text-decoration: none;
            @include media-breakpoint-down(md) {
              margin-bottom: 40px;
            }
          }
          .content {
            text-align: center;
            padding: 0 7.5rem;
            @media (max-width: 425px) {
              font-size: 14px;
              padding: 0 1rem;
            }
            @include media-breakpoint-down(md) {
              margin-bottom: 40px;
            }
            @media (min-width: 1023px) and (max-width: 1120px) {
              padding: 0 20rem;
            }
            @media (min-width: $breakPointLarge) {
              padding: 0 11.5rem;
            }
          }
        }

        &.reset-bottom {
          padding-bottom: 0px;
          border-bottom: 0;
        }
        &.success-content {
          @media (max-width: 425px) {
            padding: 0;
          }
        }
      }

      .code-entry {
        min-height: 420px;
        @include media-breakpoint-down(md) {
          min-height: 525px;
        }

        @media (max-width: 365px) {
          min-height: 625px;
        }

        @media (min-width: $breakPointMedium) {
          padding: 0 6rem;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          &:focus {
            outline: $green-c500 auto 1px;
          }
        }

        h2 {
          @media (min-width: $breakPointLarge) {
            margin-top: 30px;
          }
        }

        .btn-resend-code {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
        }
        .sign-in-user-with-onetime-password {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .fb-error {
            text-align: center;
          }
          .btn-secondary {
            margin: 15px 0 0 0;
          }
        }
      }
    }
  }
  .profile-subheading {
    font-size: 35px;
    color: $green-c500;
    text-align: center;
    margin-bottom: 1rem;
  }

  .fb-error {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .xing-footer {
    display: none;
    @media (min-width: $breakPointMedium) {
      display: flex;
    }
  }
  .content-app-btn-xing {
    display: block;
    @media (min-width: $breakPointMedium) {
      display: none;
    }
  }
  .content-app-badge {
    display: none;
    @media (min-width: $breakPointMedium) {
      display: flex;
    }
  }
  .code-entry-btn {
    @media (min-width: $breakPointLarge) {
      max-width: 480px;
    }
  }
  .course-phone-balloon {
    position: relative;
    top: 0px;
  }
  .xing-modal {
    .close {
      span {
        color: $green-c500;
      }
    }
    .plans-btn__title {
      color: $green-c500;
    }
    .card {
      .subtitle {
        color: $green-c500;
      }
    }
    .card-stoerer {
      .subtitle {
        color: $green-c500;
      }
    }
    .btn-blue-c500 {
      border-color: $green-c500;
      background-color: $green-c500;
    }
    .plans__subheading {
      @media (min-width: 2560px) {
        padding: 0 11rem;
      }
    }
  }
}
