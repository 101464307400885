// Components
//
// Define common padding and border radius sizes and more.
$border-radius: 2px;
$border-width: 2px;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: 'Poppins', Helvetica, Arial, sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 0.9375rem; // 15px, as per design (assuming 1rem = 16px)
$font-size-lg: $font-size-base * 1.25; // ~19px
$font-size-xl: $font-size-base * 1.5; // ~23px
$font-size-sm: $font-size-base * 0.75; // ~12px

$h1-font-size: $font-size-base * 2; // 30px
$h2-font-size: $font-size-base * 1.55; // ~23px
$h3-font-size: $font-size-base * 1.3; // ~20px
$h4-font-size: $font-size-base * 1; // ~15px
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.85; // ~13px

$headings-font-weight: 100; // In Zeplin, semi-bold

$font-weight-bold: 600; // 700 in Poppins looks like extra bold

$small-label-font-size: $font-size-sm;

// Buttons + Forms

$input-border-color: $grey-c100;
$input-bg: $grey-c100;
$input-placeholder-color: $grey-c600;

.form-control:focus {
  border-color: $green-c500 !important;
  box-shadow: none !important;
}
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$btn-font-weight: $font-weight-bold;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1.25rem;

$theme-colors: (
  'tertiary': #aaaaaa,
  'primary': #598096,
  'secondary': #8dc9bd,
  'success': #198754,
  'info': #d4d4d4,
  'warning': #deae39,
  'danger': #dc3545
);

// Navigation
$navbar-padding-x: 0;
$dropdown-min-width: 9rem;

$grid-gutter-width: 1.5rem;
// Spacer Extension

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    ),
    6: (
      $spacer * 5
    ),
    7: (
      $spacer * 7
    ),
    8: (
      $spacer * 10
    ),
    9: (
      $spacer * 12
    )
  ),
  $spacers
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1040px,
  xxl: 1140px
);
