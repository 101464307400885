.app-stage {
  background-color: $green-c500;
  color: $white-c500;

  &__heading {
    @include home-h2();
    color: $white-c500;
    padding-bottom: 0;
  }

  &__copy {
    @include home-copy();
    text-align: center;
  }

  .link-store {
    background-size: 150px;
    height: 300px;
    background-repeat: no-repeat;
    padding-left: 12rem;
    padding-top: 8rem;
    background-position-y: 50%;
    background-position-x: 0;

    a {
      display: inline-block;
    }

    &.appstore-link {
      background-image: url('../../images/app_stage/device_full_ios.png');

      @include media-breakpoint-up(sm) {
        background-image: none;
      }
    }

    &.googleplay-link {
      background-image: url('../../images/app_stage/device_full_android.png');

      @include media-breakpoint-up(sm) {
        background-image: none;
      }
    }

    @include media-breakpoint-up(sm) {
      background-image: none;
      padding-left: 0;
      padding-top: 0;
      height: auto;
    }
  }

  .app-devices {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      background-image: url('../../images/app_stage/device_stage.png');
      background-repeat: no-repeat;
      height: 160px;
      background-size: 100%;
      background-position-x: center;
      background-position-y: bottom;
    }

    @include media-breakpoint-up(md) {
      background-image: url('../../images/app_stage/device_stage.png');
      background-repeat: no-repeat;
      height: 215px;
      background-size: 100%;
      background-position-x: center;
      background-position-y: bottom;
    }

    @include media-breakpoint-up(lg) {
      background-image: url('../../images/app_stage/device_stage.png');
      background-repeat: no-repeat;
      height: 380px;
      background-size: auto 450px;
      background-position-x: center;
      background-position-y: bottom;
    }
  }

  ul.appstore-links {
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(xs) {
      padding: 0;
      list-style-type: none;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-around;
    }
  }
}
