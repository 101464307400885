.rating-stars {
  $starSize: 1.3rem;
  $sizeFactorLg: 1.3;

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.3rem;
    width: 100%;

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }

  &__mask-item {
    position: absolute;
    top: 0;
    left: 0;
    width: $starSize;
    aspect-ratio: 1/1;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      width: $starSize * $sizeFactorLg;
    }
  }

  &__percentage-container {
    position: relative;
    background-color: $blue-c200;
    width: $starSize;
    height: $starSize;
    padding: 0;
    border: 1px solid $white-c500;

    @include media-breakpoint-up(lg) {
      width: $starSize * $sizeFactorLg;
      height: $starSize * $sizeFactorLg;
    }
  }

  &__percentage-bar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $yellow-c500;
    height: $starSize;

    @include media-breakpoint-up(lg) {
      height: $starSize * $sizeFactorLg;
    }
  }
}
