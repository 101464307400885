.beurer-wrapper {
  //** FONT-STYLES *//

  h1 {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.9rem;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      line-height: 3rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 4rem;
      line-height: 4.5rem;
    }
  }

  h2 {
    font-size: 2.6rem;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    line-height: auto;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 2.3rem;
      text-align: left;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2.6rem;
    }
  }

  p {
    color: $grey-c800;
    font-size: 1.3rem;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
      font-size: 1.1rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.3rem;
    }
  }
}

//** HERO **//

#beurer-header {
  text-align: center;
  padding: 5rem 0 7rem 0;

  @include media-breakpoint-up(md) {
    padding: 10rem 0 10rem 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 8rem 0 10rem 0;
  }

  &.licht-header {
    background-color: $blueGrey-c500;

    @include media-breakpoint-up(md) {
      background-image: url('../../../images/landingpages/beurer/daylightBackground.jpg');
      background-blend-mode: multiply;
      background-color: $blueGrey-c500;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &.stress-header {
    background-color: $blueGrey-c500;

    @include media-breakpoint-up(md) {
      background-position: center right;
      background-image: url('../../../images/landingpages/beurer/releazerBackground.jpg');
      background-size: cover;
      background-blend-mode: multiply;
      background-color: $blueGrey-c500;
      background-repeat: no-repeat;
    }
  }

  h1,
  p {
    color: $white-c500;
    text-align: center;
  }

  p {
    font-size: 1.8rem;
    font-family: $font-family-sans-serif;
    font-weight: 300;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }

  .btn-grey-c100 {
    color: $blueGrey-c500;
    font-size: 20px;
  }
  a.btn-outline-white-c500 {
    color: $white-c500;
    font-size: 20px;
  }
  .cta-buttons {
    .btn {
      width: 230px;
      margin: 10px auto;

      @media (min-width: 576px) {
        margin: 20px;
      }

      @media (min-width: 1024px) {
        width: 250px;
        margin: 10px 20px;
      }
    }
  }
}

//** CONTENT SECTIONS **//

.section,
section.trust-band {
  padding: 6rem 0 8rem 0;
}

//** Section Explainer **//

.section-explanation {
  background-color: $grey-c100;
  text-align: center;

  @include media-breakpoint-up(md) {
    background: url('../../../images/landingpages/beurer/slopePhoneCentered.png') no-repeat;
    background-size: 45%;
    background-position-y: 5.5rem;
    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    background-size: 36%;
    background-position-y: 5.5rem;
    text-align: left;
  }

  @include media-breakpoint-up(xl) {
    background: url('../../../images/landingpages/beurer/slopePhone.png') no-repeat;
    background-size: 32%;
    background-position-y: 1rem;
  }
}

//** Section Instruction **//

.section-instruction {
  background-color: $blue-c500;
  position: relative;

  h2,
  p {
    color: $white-c500;
    text-align: center;
  }

  p {
    font-size: 1.2rem;
  }

  .instruction-table {
    width: 100%;
    display: flex; /* or inline-flex */
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;

    .instruction-point {
      flex: none;
      max-width: 178px;
      margin: 0 auto;
      margin-bottom: 2rem;

      a {
        color: $white-c500;
        text-decoration: underline;
        font-family: $font-family-sans-serif;
        font-weight: 600;
      }
    }

    .instruction-count {
      background: url('../../../images/landingpages/beurer/bullet.svg') no-repeat;
      background-size: 80%;
      background-position-x: center;
      margin-bottom: 1rem;

      span {
        font-size: 3.5rem;
        padding: 1.8rem;
        display: block;
        font-family: $font-family-sans-serif;
        font-weight: 600;
        color: $blue-c500;
        text-align: center;
      }
    }

    .store-badges a {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.5rem;
      background-position: center !important;
    }
  }

  .product-image {
    @include media-breakpoint-up(xs) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      width: 20%;
      right: 3rem;
      bottom: -3rem;
    }

    @include media-breakpoint-up(xl) {
      bottom: -10rem;
    }
  }
}

//** Section Trust and App-Stage **//
section.trust-band {
  h2 {
    text-align: center;
    font-size: 1.5rem;
  }

  p {
    text-align: center;
    font-size: 0.9rem;
  }
}

section.app-stage {
  h2 {
    text-align: center;
    font-size: 2rem;
  }

  p {
    text-align: center;
    color: $white-c500;
    font-size: 1.2rem;
  }
}
