.form-switch-dak-bgm {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90px;
  margin: 2rem 0 2rem;

  @media (min-width: $breakPointMedium) {
    height: 55px;
  }

  p {
    letter-spacing: 0.5px;
    text-transform: none;
    text-align: left;

    @media (min-width: $breakPointMedium) {
      text-align: center;
    }
  }

  .text-active {
    font-weight: 500 !important;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: $green-c500;
    text-decoration-thickness: 5px;
    color: $black;
  }

  &__button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: 0;
    flex-grow: 1;
    align-items: stretch;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 13px 15px 0;
    width: 50%;

    &:hover,
    &:focus {
      background-color: $white-c500 !important;
      @extend .text-active;
    }
  }

  .dak-inactive {
    box-shadow: inset -15px -15px 20px -20px $black-o35;
  }

  .others-inactive {
    box-shadow: inset 15px -15px 20px -20px $black-o35;
  }
}
