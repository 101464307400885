.img_insurance_cards_kk {
  position: absolute;
  bottom: -10rem;
  width: 120%;
  left: -5rem;
  z-index: -1;

  @include media-breakpoint-down(lg) {
    width: 121%;
    left: 1rem;
    bottom: -7rem;
  }

  @include media-breakpoint-down(md) {
    bottom: -7rem;
    width: 149%;
  }

  @include media-breakpoint-down(sm) {
    bottom: -11rem;
    width: 94%;
    left: 17rem;
  }

  @media (max-width: 575.98px) {
    bottom: -14rem;
    width: 21rem;
    left: 1.5rem;
  }
}

.img_balloon_expert_kk {
  @include media-breakpoint-down(sm) {
    width: 15rem;
  }
}

.img_balloon_checkout_kk {
  position: relative;
  left: -9rem;
  z-index: 0;
  width: 400px;
  top: 10rem;

  @include media-breakpoint-down(lg) {
    width: 300px;
    left: 1rem;
  }
}

.img_balloon_checkout_dak {
  height: 250px;
}

.img_balloon_instrucktions_kk {
  position: absolute;
  width: 25rem;
  right: 0;
  top: -11rem;

  @include media-breakpoint-down(lg) {
    right: 0%;
    top: -9rem;
    width: 16rem;
  }

  @include media-breakpoint-down(md) {
    position: relative;
    width: 18rem;
    top: -2rem;
    right: 0;
  }

  @include media-breakpoint-down(sm) {
    width: 14rem;
    top: -2rem;
  }
}

.styled_section_balloon_top_right {
  width: 100vw;
  background: url('../../../../images/landingpages/health_insurance/balloon_dusty_pink.svg') no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: 30%;

  @include media-breakpoint-down(lg) {
    background-size: 30%;
    background-position-y: 15%;
  }

  @include media-breakpoint-down(sm) {
    background: none;
    background-size: 20%;
  }

  @media (max-width: 575.98px) {
    background-size: 32%;
    background: none;
  }
}

.styled_section_balloon_bottom_left {
  width: 100vw;
  background: url('../../../../images/landingpages/health_insurance/balloon_ice_blue.svg') no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  background-size: 33rem;

  @media (max-width: 575.98px) {
    background-size: 22rem;
  }
}
