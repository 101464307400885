$checkbox-toggle-track-width: 48px !default;
$checkbox-toggle-track-height: 16px !default;
$checkbox-toggle-button-width: 24px !default;
$checkbox-toggle-button-height: 24px !default;
$checkbox-toggle-transition-duration: 0.28s;
$checkbox-toggle-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
$checkbox-toggle-transition: $checkbox-toggle-transition-duration $checkbox-toggle-transition-timing-function !default;

.checkbox-toggle-container {
  display: flex;
  min-height: 48px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }
}

.checkbox-toggle__input {
  position: absolute;
  opacity: 0;
}

.checkbox-toggle__track {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: $checkbox-toggle-track-width;
  height: $checkbox-toggle-track-height;
  background-color: $black-o30;
  border-radius: $checkbox-toggle-track-height;
  box-sizing: border-box;
  transition: $checkbox-toggle-transition;
  transition-property: background-color;

  .checkbox-toggle__input:checked ~ & {
    background-color: $green-c500;
  }
}
.checkbox-toggle__text {
  text-align: left;
}

.checkbox-toggle__button {
  flex-shrink: 0;
  width: $checkbox-toggle-button-width;
  height: $checkbox-toggle-button-height;
  border-radius: 100%;
  background-color: $white-c500;
  transition: $checkbox-toggle-transition;
  transition-property: transform, box-shadow;
  will-change: transform, box-shadow;

  &,
  .checkbox-toggle__input:active ~ .checkbox-toggle__track & {
    box-shadow: $black-o30 0 -1px 1px inset, $black-o30 0 1px 3px;
  }

  .checkbox-toggle:hover &,
  .checkbox-toggle__input:focus ~ .checkbox-toggle__track & {
    box-shadow: $black-o30 0 -1px 1px inset, $black-o30 0 1px 3px;
  }

  .checkbox-toggle__input:checked ~ .checkbox-toggle__track & {
    transform: translateX(100%);
  }
}
