$player-list-item-height--lg: 2.8em;
$player-list-item-height--sm: 3.8em;

.player-list-item {
  display: block;
  position: relative;

  &-content {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    padding: 0.9rem 0;

    &__title {
      margin-left: 0.5em;

      &::before {
        display: inline-block;
        content: '';
        width: 20px;
        height: 16px;
        margin-right: 0.5em;
        background-position-y: -2px;
        background-repeat: no-repeat;
      }
    }

    &__time {
      margin-right: 1em;
    }

    &:hover {
      cursor: pointer;
      background: $grey-c100;
    }

    .player-list-item-content__progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.18em;
      background: $grey-c800;
      width: 100%;
    }
  }

  &--playing {
    background: $grey-c100;

    .player-list-item-content__progress-bar {
      display: block;
    }

    .player-list-item-content__title::before {
      background-image: url('../../images/webplayer/pause-icon.svg');
    }
  }

  &--completed {
    .player-list-item-content__progress-bar {
      display: none;
    }
    .player-list-item-content__time {
      opacity: 0.5;
    }
    .player-list-item-content__title {
      opacity: 0.5;
      display: flex;

      &::before {
        background-image: url('../../images/webplayer/check-icon.svg');
        background-position: bottom;
      }
    }
  }

  &--available {
    .player-list-item-content__progress-bar {
      display: none;
    }
    .player-list-item-content__time {
      opacity: 0.5;
    }
    .player-list-item-content__title {
      opacity: 0.5;
      &::before {
        background-image: url('../../images/webplayer/play-icon.svg');
        background-position-y: -3px;
      }
    }
  }

  &--selected {
    background: $grey-c100;

    .player-list-item-content__progress-bar {
      display: none;
    }

    .player-list-item-content__title::before {
      background-image: url('../../images/webplayer/play-icon.svg');
      background-position-y: -3px;
    }
  }

  &--default {
    .player-list-item-content__progress-bar {
      display: none;
    }

    .player-list-item-content__title::before {
      background-image: url('../../images/webplayer/play-icon.svg');
      background-position-y: -3px;
    }
  }

  &--locked {
    .player-list-item-content__progress-bar {
      display: none;
    }
    .player-list-item-content__time {
      opacity: 0.5;
    }
    .player-list-item-content__title {
      opacity: 0.5;
      &::before {
        background-image: url('../../images/webplayer/locker-icon.svg');
        height: 17px;
        background-position-y: -3px;
      }
    }
  }
}
