.form-group {
  .form-control:not(.is-invalid) {
    margin-bottom: 0;
  }
}

.password-change-btn {
  margin-top: 15px;
}

$letter-spacing: 1.08px;
$border-radius: 6px;
$input-placeholder-color: $grey-c500;
$placeholder-font-size: 13px;

//inputs and placeholder
.checkout,
.profile-content {
  .invalid-feedback {
    letter-spacing: 0.3px;
  }
  .form-control {
    border-radius: $border-radius;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $input-placeholder-color;
      letter-spacing: $letter-spacing;
      font-size: $placeholder-font-size;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $input-placeholder-color;
      letter-spacing: $letter-spacing;
      font-size: $placeholder-font-size;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $input-placeholder-color;
      letter-spacing: $letter-spacing;
      font-size: $placeholder-font-size;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: $input-placeholder-color;
      letter-spacing: $letter-spacing;
      font-size: $placeholder-font-size;
    }
  }

  .section-container.background-grey-c100 {
    .form-control {
      background-color: $white-c500;
      border-radius: $border-radius;
    }
  }
}

.form-check-input {
  margin-left: 0;
  margin-right: 0.5rem;
}
