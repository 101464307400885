.notification-box-active {
  .navigation {
    &.fixed-top {
      top: 63px;
      @include media-breakpoint-up(md) {
        top: 40px;
      }
    }
  }

  .plans {
    @include media-breakpoint-up(xs) {
      margin-top: 8rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 10rem;
    }
  }

  .b2b-landing-page {
    padding-top: 120px;
  }
  .health-insurance-page {
    padding-top: 40px;
  }
  .landingpage {
    margin-top: 0;
  }
  main {
    &.home {
      margin-top: 0;
    }

    @include media-breakpoint-up(xs) {
      margin-top: 6rem;
    }

    @include media-breakpoint-up(sm) {
      margin-top: 8rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 10rem;
    }
  }

  .library .library-nav-col {
    height: 12.8rem;

    @include media-breakpoint-up(md) {
      height: 11.5rem;
    }
  }

  .library .library-content-col {
    margin-top: 7.8rem;

    @include media-breakpoint-up(md) {
      margin-top: 5rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
  }

  .library-nav-wrapper--collapse {
    overflow-y: scroll !important;
    max-height: 77vh !important;

    &::-webkit-scrollbar {
      width: 0.1em;
      background-color: $white-c500;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $black-c500;
    }

    @include media-breakpoint-up(md) {
      overflow-y: visible;
      height: 100%;
      padding-bottom: 0;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $white-c500;
    }
  }
}

.notification-box {
  padding: 10.5px 23px 15px 17px;
  background-color: $blue-c500;
  width: 100%;
  height: 63px;
  font-size: 0.8rem;
  font-weight: 200;
  font-family: 'Poppins';
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $white-c500;
  display: flex;
  justify-content: center;
  @media (min-width: 375px) {
    font-size: 0.9rem;
    font-weight: 400;
  }
  @include media-breakpoint-up(md) {
    height: 40px;
  }
  .link {
    &:hover {
      color: $grey-c500 !important;
    }
  }
}
.notification-box-close-mark {
  padding-left: 1.5rem;
  &::after {
    color: $white-c500;
    content: '×';
    font-size: 1.3rem;
    position: relative;
    top: -5px;
  }
}

.rnc__notification {
  height: 63px;
  @include media-breakpoint-up(md) {
    height: 40px;
  }
}
.rnc__notification-item {
  box-shadow: none;
}
