.podcast {
  margin-top: 86px;

  h1 {
    font-weight: 300;
    font-size: 30px;

    @media (min-width: $breakPointMedium) {
      font-size: 45px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 60px;
    }
  }

  h2 {
    font-weight: 300;
    font-size: 24px;

    @media (min-width: $breakPointMedium) {
      font-size: 36px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 48px;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 14px;

    @media (min-width: $breakPointMedium) {
      font-size: 19px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 24px;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 12px;

    @media (min-width: $breakPointMedium) {
      font-size: 16px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 21px;
    }
  }

  p {
    font-weight: 300;
    font-size: 12px;

    @media (min-width: $breakPointMedium) {
      font-size: 16px;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 21px;
    }
  }

  .download-link {
    width: 100%;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 5px;
    font-weight: 500;
    color: $grey-c800;
    white-space: pre-wrap;
    margin-top: 3px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    &:hover,
    :focus {
      color: $grey-c800;
    }

    @media (min-width: $breakPointLarge) {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 7px;
    }

    &--underline {
      text-decoration: underline;
    }
  }

  .btn-podcast-itunes {
    border-color: $green-c500;
    background-color: $green-c500;

    &:hover {
      background-color: $green-c600;
      border-color: $green-c600;
    }
  }

  .gradient-line-container {
    position: absolute;
    width: 100%;
  }

  .gradient-line {
    height: 1px;
    background-color: $black;
    width: 100%;
  }

  .gradient-line-10 {
    opacity: 0.1;
  }

  .gradient-line-5 {
    opacity: 0.05;
  }

  .gradient-line-2 {
    opacity: 0.02;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 18px;
    text-align: center;

    @media (min-width: $breakPointMedium) {
      margin-bottom: 57px;
    }

    h1 {
      margin-top: 23px;
      padding-left: 10px;
      padding-right: 10px;

      @media (min-width: $breakPointLarge) {
        margin-top: 20px;
      }
    }

    h3 {
      margin-top: 12px;

      @media (min-width: $breakPointLarge) {
        margin-top: 10px;
      }
    }
  }

  .header-logo-container {
    overflow: hidden;

    @media (min-width: $breakPointMedium) {
      margin-right: 250px;
    }
  }

  .header-logo {
    background: url('../../images/podcast/podcast-logo-header.svg') no-repeat;
    background-size: cover;
    width: 194px;
    height: 188px;
    margin-top: -65px;

    @media (min-width: $breakPointMedium) {
      width: 285px;
      height: 277px;
      margin-top: -87px;
    }

    @media (min-width: $breakPointLarge) {
      width: 387px;
      height: 376px;
      margin-top: -130px;
    }
  }

  #links-section-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: $grey-c100;

    #content-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: $breakPointMedium) {
        padding-bottom: 130px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }

    h2 {
      margin-top: 33px;
      margin-bottom: 7px;
      align-self: flex-start;

      @media (min-width: $breakPointMedium) {
        margin-top: 79px;
        margin-bottom: 27px;
      }
    }

    #bottom-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 80px;

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
      }

      #text-container {
        width: 100%;
        margin-right: 0;

        @media (min-width: $breakPointLarge) {
          width: 600px;
          margin-right: 50px;
        }
      }

      #link-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 100px;

        @media (min-width: $breakPointLarge) {
          width: 386px;
          margin-bottom: 0;
        }

        h4 {
          margin-top: 14px;
          margin-bottom: 0;

          @media (min-width: $breakPointLarge) {
            margin-top: 0;
            margin-bottom: 18px;
          }
        }

        a {
          width: 100%;
        }

        .btn-podcast {
          margin-top: 10px;
          width: 100%;
          height: 44px;
          line-height: 44px;
          font-weight: 300;
          font-size: 14px;
          padding: 0;
          color: $white-c500;

          @media (min-width: $breakPointMedium) {
            margin-top: 20px;
            height: 50px;
            line-height: 50px;
            font-size: 19px;
          }

          @media (min-width: $breakPointLarge) {
            margin-top: 20px;
            height: 79px;
            line-height: 79px;
            font-size: 24px;
          }
        }
      }
    }
  }

  #team-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    @media (min-width: $breakPointLarge) {
      margin-bottom: 170px;
    }

    h2 {
      margin-top: 130px;

      @media (min-width: $breakPointLarge) {
        margin-top: 100px;
      }
    }

    .illu-container {
      position: absolute;
      margin-top: -150px;
      margin-left: 0;

      @media (min-width: $breakPointLarge) {
        margin-top: -300px;
        margin-right: 250px;
        right: 50%;
      }
    }

    .illu {
      background: url('../../images/podcast/podcast-illu-team.svg') no-repeat;
      background-size: cover;
      width: 264px;
      height: 257px;

      @media (min-width: $breakPointLarge) {
        width: 528px;
        height: 514px;
      }
    }

    #bottom-container {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
        margin-top: 25px;
      }

      .author-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 23px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;

        @media (min-width: $breakPointMedium) {
          padding-left: 100px;
          padding-right: 100px;
        }

        @media (min-width: $breakPointLarge) {
          width: 514px;
          margin-top: 0;
          margin-left: 25px;
          margin-right: 25px;
          padding-left: 0;
          padding-right: 0;
        }

        h4 {
          margin-top: 20px;
        }

        p {
          margin-top: 5px;
        }
      }

      .boris-image {
        background: url('../../images/podcast/podcast-boris.jpg') no-repeat;
        background-size: cover;
        width: 120px;
        height: 120px;

        @media (min-width: $breakPointMedium) {
          width: 240px;
          height: 240px;
        }
      }

      .sinja-image {
        background: url('../../images/podcast/podcast-sinja.jpg') no-repeat;
        background-size: cover;
        width: 120px;
        height: 120px;

        @media (min-width: $breakPointMedium) {
          width: 240px;
          height: 240px;
        }
      }
    }
  }

  #episode-section-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: $grey-c100;

    h2 {
      margin-top: 42px;

      @media (min-width: $breakPointLarge) {
        margin-top: 65px;
      }
    }

    .illu-container {
      position: relative;
      margin-top: 10px;
      margin-left: 0;
      left: inherit;

      @media (min-width: $breakPointLarge) {
        position: absolute;
        margin-top: -210px;
        margin-left: 335px;
        left: 50%;
      }
    }

    .illu {
      background: url('../../images/podcast/podcast-illu-episodes.svg') no-repeat;
      background-size: cover;
      width: 208px;
      height: 203px;

      @media (min-width: $breakPointLarge) {
        width: 416px;
        height: 405px;
      }
    }

    #bottom-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 15px;
      margin-bottom: 45px;
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: $breakPointMedium) {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media (min-width: $breakPointLarge) {
        flex-direction: row;
        margin-top: 55px;
        margin-bottom: 95px;
        padding-left: 0;
        padding-right: 0;
      }

      .episode-container {
        border-radius: 10px;
        box-shadow: 0 0 7px 0 $black-o30;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: $white-c500;
        padding: 18px;
        padding-bottom: 27px;
        margin-top: 16px;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        border: solid 1px $white-c500;

        @media (min-width: $breakPointMedium) {
          margin-top: 30px;
        }

        @media (min-width: $breakPointLarge) {
          border-radius: 20px;
          box-shadow: 0 0 15px 0 $black-o30;
          width: 330px;
          padding: 22px;
          padding-bottom: 37px;
          margin-top: 0;
          margin-left: 20px;
          margin-right: 20px;
          border: solid 2px $white-c500;
        }

        &__highlight {
          border: solid 1px $yellow-c500;

          @media (min-width: $breakPointLarge) {
            border: solid 2px $yellow-c500;
          }
        }

        p {
          font-size: 12px;
          margin-top: 3px;
          margin-bottom: 3px;

          @media (min-width: $breakPointMedium) {
            font-size: 16px;
          }

          @media (min-width: $breakPointLarge) {
            font-size: 18px;
            margin-top: 5px;
            margin-bottom: 13px;
          }
        }

        h3 {
          font-weight: 300;
          font-size: 18px;

          @media (min-width: $breakPointMedium) {
            font-size: 24px;
          }

          @media (min-width: $breakPointLarge) {
            font-size: 36px;
          }
        }

        h4 {
          margin-top: 0;
          margin-bottom: 5px;

          @media (min-width: $breakPointLarge) {
            margin-top: 4px;
            margin-bottom: 6px;
          }
        }

        .btn-arrow-container {
          position: absolute;
          top: -8px;
          right: -4px;

          @media (min-width: $breakPointLarge) {
            top: 6px;
            right: 6px;
          }
        }

        .btn-arrow {
          background: url('../../images/podcast/podcast-arrow-down.svg') no-repeat;
          background-size: cover;
          width: 12px;
          height: 7px;

          @media (min-width: $breakPointLarge) {
            width: 24px;
            height: 14px;
          }

          &_flipped {
            transform: scaleY(-1);
          }
        }

        .btn-episode {
          width: 100%;
          margin-top: 15px;
        }
      }
    }
  }

  #review-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: $breakPointMedium) {
      padding-left: 50px;
      padding-right: 50px;
    }

    h2 {
      margin-top: 26px;

      @media (min-width: $breakPointLarge) {
        margin-top: 50px;
      }
    }

    #item-container {
      max-width: 1070px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 24px;
      margin-bottom: 0;

      @media (min-width: $breakPointLarge) {
        margin-top: 55px;
        margin-bottom: 30px;
      }

      .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 45px;

        @media (min-width: $breakPointLarge) {
          margin-bottom: 90px;
        }

        h4 {
          display: inline;
          margin: 0;
        }
        p {
          display: inline;
          margin: 0;
          line-height: inherit;

          @media (min-width: $breakPointLarge) {
            line-height: inherit;
          }
        }

        .top-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;

          @media (min-width: $breakPointLarge) {
            margin-bottom: 18px;
          }

          .stars {
            background: url('../../images/podcast/podcast-stars.svg') no-repeat;
            background-size: cover;
            width: 65px;
            height: 13px;
            margin-right: 7px;

            @media (min-width: $breakPointLarge) {
              width: 130px;
              height: 26px;
              margin-right: 13px;
            }
          }
        }

        .bottom-container {
          line-height: 16px;

          @media (min-width: $breakPointMedium) {
            line-height: inherit;
          }

          h4 {
            margin-right: 7px;
          }
        }
      }
    }

    #balloonie {
      background: url('../../images/podcast/podcast-balloonie.png') no-repeat;
      background-size: cover;
      // original 554px x 666px
      width: 139px;
      height: 167px;
      background-size: cover;
      margin-bottom: 120px;

      @media (min-width: $breakPointLarge) {
        width: 277px;
        height: 333px;
      }
    }
  }
}
