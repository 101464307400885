.list-layout-block {
  display: block;
  margin-bottom: 1.4em;

  &__headline {
    margin-bottom: 0.5em;
    font-family: $font-family-sans-serif;
    font-weight: 300;
    font-size: 1em;
    text-transform: uppercase;
  }

  &__items {
    column-count: 1;
    -webkit-column-count: 1;

    @include media-breakpoint-up(md) {
      column-count: 2;
      -webkit-column-count: 2;
    }
  }
}
