.content-by-category {
  margin-top: 4.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 3rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -4rem;
  }

  &__header {
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1.563rem;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(lg) {
      align-items: center;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
  }

  &__img-wrapper {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 6.063rem;
    width: 6.063rem !important;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      height: 9.375rem;
      width: 9.375rem !important;
    }
  }

  &__title {
    margin: 0.5rem 0 0.4rem;

    @include media-breakpoint-up(lg) {
      margin: 0 0 0.5rem;
      text-transform: uppercase;
    }
  }

  &__description {
    text-align: center;
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
    }
  }
}
