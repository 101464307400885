.subscriptionAlerts__item {
  border-radius: 15px;

  &--info {
    color: $blue-c800;
    background-color: $blue-c200;
    border-color: $blue-c200;
  }
  &--link {
    text-decoration: underline;
  }
}
