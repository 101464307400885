.btn {
  font-family: $poppins;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.813rem;
  border-radius: 8px;
  letter-spacing: 0.038rem;
}

.btn-kk-font-size {
  font-size: $font-size-base_kk;
}

.btn-primary,
.btn-secondary,
.btn-tertiary {
  color: $white-c500;

  &:disabled {
    background-color: $grey-c600;
    border-color: $grey-c600;
  }
}

.btn-primary {
  border-color: $green-c500;

  &:hover,
  &:focus {
    background-color: $green-c600;
    border-color: $green-c600;
  }
}

.btn-secondary {
  background-color: $green-c500;
  border-color: $green-c500;

  &:hover,
  &:focus,
  &:active {
    background-color: $green-c600;
    border-color: $green-c600;
    color: $white-c500;
  }
}

.btn-blue-c500 {
  border-color: $blue-c500;
  background-color: $blue-c500;
  &:hover,
  &:focus {
    background-color: $blue-c600;
    border-color: $blue-c600;
  }
}

.btn-yellow-c500 {
  border-color: $yellow-c500;
  background-color: $yellow-c500;
  &:hover,
  &:focus {
    background-color: $yellow-c600;
    border-color: $yellow-c600;
  }
}

.btn-pink-c500 {
  border-color: $pink-c500;
  background-color: $pink-c500;
  color: $white-c500;

  &:hover,
  &:focus {
    background-color: $pink-c600;
    border-color: $pink-c600;
    color: $white-c500;
  }
  &:disabled {
    background-color: $grey-c600;
    border-color: $grey-c600;
  }
}

.btn-grey-c100 {
  background-color: $grey-c100;
  color: $green-c500;
  border-color: $grey-c100;

  &:hover,
  &:focus {
    background-color: $green-c600;
    border-color: $green-c600;
    color: $white-c500;
  }
}

.btn-green-c500 {
  background-color: $green-c500;
  color: $white-c500;

  &:hover,
  &:focus {
    background-color: $green-c600;
    border-color: $green-c600;
    color: $white-c500;
  }
}

.btn-dark-green {
  background-color: $green-c600;
  color: $white-c500;

  &:hover,
  &:focus {
    background-color: $green-c200;
    border-color: $green-c200;
    color: $white-c500;
  }
}
// BTN BACKGROUND WHITE – TEXT COLOR
.btn-white {
  background-color: $grey-c100;
  border-color: $grey-c100;

  &:hover,
  &:focus {
    color: $white-c500;
  }

  &:focus {
    background-color: $grey-c100;
    border-color: $grey-c100;
  }
}

.btn-white-blue-c500 {
  @extend .btn-white;
  color: $blue-c500;
  &:hover,
  &:focus {
    background-color: $blue-c600;
    border-color: $blue-c600;
  }
}

.btn-white-yellow-c500 {
  @extend .btn-white;
  color: $yellow-c500;
  &:hover,
  &:focus {
    background-color: $yellow-c600;
    border-color: $yellow-c600;
  }
}

.btn-white-green-c500 {
  @extend .btn-white;
  color: $green-c500;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $green-c600;
    border-color: $green-c600;
    color: $white-c500;
  }
}

.btn-white-pink-c500 {
  @extend .btn-white;
  color: $pink-c500;
  &:hover,
  &:focus {
    background-color: $pink-c600;
    border-color: $pink-c600;
  }
}

/////////////////////////////////////////

.btn-outline-white-c500 {
  color: $white-c500;
  border-color: $white-c500;

  &:hover,
  &:focus {
    background-color: $white-c500;
    color: $green-c500 !important;
  }
}

.btn-outline-blue-c500 {
  color: $blue-c500;
  background-color: $white-c500;
  border-color: $blue-c500;

  &:hover,
  &:active,
  &:focus {
    background-color: $white-c500;
    color: rgba(89, 128, 150, 0.6) !important;
    border-color: rgba(89, 128, 150, 0.6);
  }
}

//////////////////////////////////

.btn-link {
  color: $green-c500;

  &:hover {
    color: $green-c600;
  }
}

.btn-link-underlined {
  background: $white-c500;
  color: $green-c500;
  text-decoration: underline;
  letter-spacing: 0.063rem;
  font-size: 0.85rem;
  border: none;

  &:hover {
    color: $green-c600;
    background: $white-c500;
  }
}

.textlink {
  font-weight: 500;
  color: $green-c500;
  text-decoration: underline;
}

.btn-special {
  font-size: 1.188rem;
  max-width: 320px;
}

.plans-btn__call-to-action {
  font-size: 1rem;
  font-weight: 500;

  @media (max-width: 575.98px) {
    line-height: 1.2rem;
  }
}

.btn-abo-renew {
  margin-left: 20px;
  @include media-breakpoint-down(md) {
    margin-left: 0;
  }
}

///// BTN PARTNER //////////////////////////
.btn-health,
.btn-bkk,
.btn-xing,
.btn-gothaer {
  max-width: 100%;
  min-width: 280px;
  margin-top: 40px;
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

.width-100 {
  max-width: 100%;
  min-width: 280px;
}

.btn-dak-orange {
  background-color: $dak-orange;
  border-color: $dak-orange;
  color: $white-c500;
  padding-left: 15px;
  padding-right: 15px;

  &:hover,
  &:active,
  &:focus {
    background-color: $dak-orange-darker;
    border-color: $dak-orange-darker;
    color: $white-c500;
  }
}

.btn-white-space-normal {
  white-space: normal;
}

.btn-white-space-pre {
  white-space: pre !important;
}
