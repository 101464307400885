.background-blue-c500 {
  background-color: $blue-c500;
}

.background-green-c500 {
  background-color: $green-c500 !important;
}

.background-grey-c100 {
  background-color: $grey-c100;
}

.text-blueGrey-c500 {
  color: $blueGrey-c500;
}

.text-pink-c500 {
  color: $pink-c500;
}

.text-grey-c600 {
  color: $grey-c600;
}

.text-green-c500 {
  color: $green-c500;
}

.text-grey-c800 {
  color: $grey-c800;
}

.text-white-c500 {
  color: $white-c500;
}
