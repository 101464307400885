.landingpage-form-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media (min-width: $breakPointMedium) {
    &::before {
      content: '';
      background-image: url('../../../images/background_circle_down_left.svg');
      width: 365px;
      height: 506px;
      position: absolute;
      top: 368px;
      left: -192px;
      z-index: 0;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &::after {
      content: '';
      background-image: url('../../../images/background_circle_up_right.svg');
      width: 186px;
      height: 246px;
      position: absolute;
      top: 200px;
      right: 0;
      z-index: 0;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 15px 0 0;

    @media (min-width: $breakPointMedium) {
      padding: 0 50px;
      margin-bottom: 45px;
    }

    @media (min-width: $breakPointLarge) {
      flex-direction: row;
      margin-top: 55px 0 95px;
      padding: 0 0;
    }
  }

  &__rounded-card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;

    @media (min-width: $breakPointMedium) {
      border-radius: 10px;
      margin-top: 2rem;
      box-shadow: 0 0 10px 0 $black-o30;
      background-color: $white-c500;
    }

    @media (min-width: $breakPointLarge) {
      border-radius: 15px;
      box-shadow: 0 0 40px 0 $black-o30;
      width: 750px;
      margin: 2rem 0;
    }
  }
}
