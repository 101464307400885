//The root variables are defined in mime_elements.
/* Green */
$green-c100: var(--green-c100);
$green-c200: var(--green-c200);
$green-c500: var(--green-c500);
$green-c600: var(--green-c600);
$green-c800: var(--green-c800);

/* Pink */
$pink-c100: var(--pink-c100);
$pink-c200: var(--pink-c200);
$pink-c500: var(--pink-c500);
$pink-c600: var(--pink-c600);
$pink-c800: var(--pink-c800);

/* Purple */
$purple-c100: var(--purple-c100);
$purple-c200: var(--purple-c200);
$purple-c500: var(--purple-c500);
$purple-c600: var(--purple-c600);
$purple-c800: var(--purple-c800);

/* Blue */
$blue-c100: var(--blue-c100);
$blue-c200: var(--blue-c200);
$blue-c500: var(--blue-c500);
$blue-c600: var(--blue-c600);
$blue-c800: var(--blue-c800);

/* Yellow */
$yellow-c100: var(--yellow-c100);
$yellow-c200: var(--yellow-c200);
$yellow-c500: var(--yellow-c500);
$yellow-c600: var(--yellow-c600);
$yellow-c800: var(--yellow-c800);

/* Black Color*/
$black-c500: var(--black-c500);

/* Black Opacity*/
$black-o00: var(--black-o00);
$black-o10: var(--black-o10);
$black-o20: var(--black-o20);
$black-o30: var(--black-o30);
$black-o35: var(--black-o35);
$black-o50: var(--black-o50);
$black-o80: var(--black-o80);
$black-o95: var(--black-o95);

/* White Color */
$white-c500: var(--white-c500);

/* White Opcity */
$white-o00: var(--white-o00);
$white-o12: var(--white-o12);
$white-o20: var(--white-o20);
$white-o30: var(--white-o30);
$white-o35: var(--white-o35);
$white-o50: var(--white-o50);
$white-o60: var(--white-o60);
$white-o80: var(--white-o80);
$white-o95: var(--white-o95);

/* Grey */
$grey-c100: var(--grey-c100);
$grey-c200: var(--grey-c200);
$grey-c400: var(--grey-c400);
$grey-c500: var(--grey-c500);
$grey-c600: var(--grey-c600);
$grey-c800: var(--grey-c800);

/* BlueGrey */
$blueGrey-c200: var(--blueGrey-c200);
$blueGrey-c500: var(--blueGrey-c500);

/* Red */
$error-c500: var(--error-c500);
$success-c500: var(--success-c500);

// Other variables are only here defined, not in mime_elements
/* Others */
$dak-orange: #ef7d00;
$dak-orange-darker: darken($dak-orange, 10%);
